import { useEffect, useRef } from 'react'

const useTimeout = (callback: (...args: any) => void, delay: number, allow = true) => {
  const savedCallback = useRef<(...args: any) => void>()
  const timeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
    }
  }, [timeout])

  useEffect(() => {
    const handler = (...args) => savedCallback.current(...args)

    if (allow && delay !== null) {
      timeout.current = setTimeout(handler, delay)
    }
  }, [allow, delay])
}

export default useTimeout
