export function randomizeArray<T>(arr: T[]): T[] {
  return [...arr].sort(() => 0.5 - Math.random())
}

export function splitText(text: string, perCharacter: boolean) {
  if (!text) {
    return []
  }

  return text.split(perCharacter ? '' : ' ').map((item: string, index, array) => {
    if (perCharacter) {
      return item || '\u00A0'
    }

    const isLastWord = index === array.length - 1
    return isLastWord ? item : item + '\u00A0'
  })
}

export function getRandomFloat(min: number, max: number) {
  return Math.random() * (max - min) + min
}

export function getRandomInteger(min: number, max: number) {
  const minimum = Math.ceil(min)
  const maximum = Math.floor(max)

  return Math.floor(Math.random() * (maximum - minimum + 1) + minimum)
}

export function getRandomItemFromArray(arr: any[]) {
  return arr[Math.floor(Math.random() * arr.length)]
}

export function getNextAvailableIndex(unavailableIndices: number[], index: number) {
  if (unavailableIndices.indexOf(index) === -1) {
    return index
  }

  return getNextAvailableIndex(unavailableIndices, index + 1)
}

let uid = 0

export function getUID() {
  return uid++
}
