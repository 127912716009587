import { useEffect, useState } from 'react'

const useDocumentReady = () => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    function handleLoadWindow() {
      setIsReady(true)
    }

    if (document.readyState === 'complete') {
      handleLoadWindow()
    } else {
      window.addEventListener('load', handleLoadWindow)
    }

    return () => {
      window.removeEventListener('load', handleLoadWindow)
    }
  }, [])

  return isReady
}

export default useDocumentReady
