import Link from 'next/link'
import { chakra, forwardRef } from '@chakra-ui/react'

import { MotionBox } from 'components/Motion'

import { useAppDispatchContext } from 'context/AppContext'
import { useEffect, useState } from 'react'

interface ButtonProps {
  children: React.ReactNode
  className?: string
  href?: string
  target?: string
  onClick?: () => void
  prefetch?: boolean
}

const Button = forwardRef(({ children, className, href, onClick, prefetch, ...props }: ButtonProps, ref) => {
  const [isCmdKeyPressed, setIsCmdKeyPressed] = useState(false)

  const dispatcher = useAppDispatchContext()

  function handleClickInternalLink(e: Event) {
    if (isCmdKeyPressed) {
      return
    }

    e.preventDefault()

    if (typeof onClick === 'function') {
      onClick()
    }

    dispatcher.changeRoute(href)
  }

  useEffect(() => {
    function handleKeydown(e) {
      setIsCmdKeyPressed(e?.metaKey)
    }

    function handleKeyup() {
      setIsCmdKeyPressed(false)
    }

    window.addEventListener('keydown', handleKeydown)
    window.addEventListener('keyup', handleKeyup)

    return () => {
      window.removeEventListener('keydown', handleKeydown)
      window.removeEventListener('keyup', handleKeyup)
    }
  }, [])

  // external link to open in new window
  if (href?.length && checkExternalLink(href)) {
    return (
      <a href={href} target="_blank">
        <MotionBox as="span" className={className} display="inline-block" {...props} ref={ref}>
          {children}
        </MotionBox>
      </a>
    )
  }

  // for internal links:
  return href ? (
    <Link href={href} passHref scroll={false} prefetch={prefetch}>
      <MotionBox
        as="a"
        className={className}
        display="inline-block"
        {...props}
        onClick={handleClickInternalLink}
        ref={ref}
      >
        {children}
      </MotionBox>
    </Link>
  ) : (
    <MotionBox as="button" className={className} onClick={onClick} {...props} ref={ref}>
      {children}
    </MotionBox>
  )
})

function checkExternalLink(url: string) {
  return !!url?.match(/(http|mailto:|tel:)/)
}

export default chakra(Button)
