const Button = {
  baseStyle: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    color: 'var(--chakra-colors-white)',
    bg: 'var(--chakra-colors-red)',
    bgColor: 'var(--chakra-colors-red)',
    borderRadius: 0,
    py: 6,
    mx: 0,
    textTransform: 'uppercase',
    minH: '60px',
    fontSize: '18px',
    _hover: {
      bg: 'redHover',
      bgColor: 'redHover',
      _disabled: {
        bgColor: 'var(--chakra-colors-red)',
      },
    },
  },
  variants: {
    outline: () => ({
      bgColor: 'transparent',
      borderColor: 'lightBlue',
      px: 8,
      _hover: {
        bgColor: 'lightBlueHover',
      },
      _active: {
        bg: 'lightBlueActive',
        bgColor: 'lightBlueActive',
      },
    }),
    outlineBlack: () => ({
      bgColor: 'transparent',
      border: '1px solid black',
      color: 'black',
      px: 8,
      _hover: {
        bgColor: 'blackAlpha.200',
      },
      _disabled: {
        color: 'white',
        bgColor: 'blackAlpha.700',
        border: 'none',
      },
    }),
    grey: () => ({
      bgColor: 'blackAlpha.300',
      border: '1px solid transparent',
      color: 'white',
      px: 8,
      _hover: {
        bgColor: 'blackAlpha.300',
        border: '1px solid transparent',
        color: 'white',
      },
      _disabled: {
        bgColor: 'blackAlpha.300',
        border: '1px solid transparent',
        color: 'white',
      },
    }),
  },
}

export default Button
