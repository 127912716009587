import { useEffect, useMemo } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Vector3 } from 'three'
import { clamp, mix } from 'utils/math'
import { debugFolder, debugInput } from '../utils/debug'
import CompositeEffect from '../materials/CompositeEffect'
import { Animations, app, dispatcher, Events, tweener } from '../global'
import { Settings } from 'hooks/useAssetLoader'
import { IS_DEV } from '../../constants'

export default function Composite() {
  const effect = useMemo(() => new CompositeEffect(), [])

  const initDebug = () => {
    const postFolder = debugFolder('Post')
    const folder = debugFolder('Composite', false, postFolder)
    debugInput(folder, effect, 'minRange', {
      min: 0,
      max: 1,
      step: 0.01,
    })
    debugInput(folder, effect, 'maxRange', {
      min: 0,
      max: 2,
      step: 0.01,
    })
    debugInput(folder, effect, 'dustOpacity', {
      min: 0,
      max: 1,
      step: 0.01,
    })
    debugInput(folder, effect, 'lensFlare', {
      min: 0,
      max: 1,
      step: 0.01,
    })
    debugInput(folder, effect, 'lensX', {
      min: -1,
      max: 1,
      step: 0.01,
    })
    debugInput(folder, effect, 'lensY', {
      min: -1,
      max: 1,
      step: 0.01,
    })
    debugInput(folder, effect, 'haloAmount', {
      min: 0,
      max: 1,
      step: 0.01,
    })
    debugInput(folder, effect, 'specular', {
      min: 0,
      max: 500,
      step: 0.01,
    })
    debugInput(folder, effect, 'normalAmount', {
      min: 0,
      max: 1000,
      step: 0.01,
    })
    const positions = {
      light: new Vector3(1, 1, 0.5),
    }
    debugInput(folder, positions, 'light', {
      onChange: (value: Vector3) => {
        effect.lightPosition = value
      },
    })
  }

  const onAnimation = (evt: any) => {
    if (evt.value === Animations.Intro) {
      const ease = [0.75, 0, 0.25, 1]
      tweener.to(effect, 2, {
        vignette: 1,
        ease: ease,
      })
    }
  }

  useEffect(() => {
    dispatcher.addEventListener(Events.ANIMATION, onAnimation)
    if (IS_DEV) initDebug()
    return () => {
      dispatcher.removeEventListener(Events.ANIMATION, onAnimation)
    }
  }, [])

  const { camera } = useThree()
  useFrame(() => {
    if (app.headerVisible) {
      const pos = new Vector3()
      camera.getWorldDirection(pos)
      const horizontal = clamp(-1, 1, Math.sin(clamp(-0.5, 0.5, pos.x) * Math.PI)) * 0.5
      const vertical = clamp(-1, 1, pos.y * 10) * 0.5
      effect.lensX = horizontal
      effect.lensY = vertical
      effect.dustOpacity = 1
      const lightX = clamp(-1, 1, -pos.x * 10)
      const lightY = clamp(-1, 1, -pos.y * 20)
      effect.lightPosition = new Vector3(lightX, lightY, 0.5)
      effect.lensFlare = 1
    } else if (app.footerVisible) {
      effect.lensX = mix(-0.5, 0.5, app.percent)
      effect.lensY = 0.5
      effect.dustOpacity = 0.5
      effect.lensFlare = Settings.mobile ? 0.25 : 1
    }
  })

  return <primitive object={effect} dispose={null} />
}
