import { Global, css } from '@emotion/react'

import breakpoints from 'constants/breakpoints'
import { fluidType } from 'utils/designHelper'
import colors from 'constants/colors'

const GlobalStyle = () => (
  <Global
    styles={css`
      html {
        overflow-x: hidden;
        font-size: ${fluidType(`${breakpoints.sm}px`, `${breakpoints.xl}px`, 16, 21)};
      }

      body {
        overflow-x: hidden;
        background-color: ${colors.blue_13};
        transition: background-color 0.3s linear;

        * {
          box-sizing: border-box;
          word-wrap: break-word;
        }
      }

      div#__next {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        word-wrap: break-word;

        color: #fff;
        font-family: 'EsKlarheitGroteskBook', Georgia, serif;
      }

      @media (min-width: ${breakpoints.xl}px) {
        html {
          font-size: 16px;
      }
    `}
  />
)

export default GlobalStyle
