import { BladeApi, BladeController, ButtonApi, InputBindingApi, MonitorBindingApi, View } from '@tweakpane/core'
import { FolderApi, Pane, TpChangeEvent } from 'tweakpane'
import * as EssentialsPlugin from '@tweakpane/plugin-essentials'
import * as TweakpaneImagePlugin from 'tweakpane-image-plugin'
import { RepeatWrapping, Texture } from 'three'
import { loadImageElement } from '../../utils/dom'
import { clamp } from '../../utils/math'
import { IS_DEV } from '../../constants'

BladeApi.prototype['uuid'] = -1
const uuids = {
  color: -1,
  folder: -1,
  image: -1,
  input: -1,
  options: -1,
  toggle: -1,
  reset: function () {
    this.color = -1
    this.folder = -1
    this.image = -1
    this.input = -1
    this.options = -1
    this.toggle = -1
  },
}
function nextPaneUUID(type) {
  uuids[type]++
  return `${type}_${uuids[type]}`
}

function clampColor(value: number): number {
  return clamp(0, 255, Math.round(value))
}

let gui: Pane | undefined = undefined
let core = undefined
let folders = {}
let panes = {}
// export const IS_DEV = true

export type Vec2 = {
  x: number
  y: number
  z: number
}

export type Vec3 = {
  x: number
  y: number
  z: number
}

function exportFolders() {
  const data = {}
  for (const i in folders) {
    const folder = folders[i]
    exportFolder(data, folder)
  }
  delete data['Presets']
  // console.log(data)
  console.log(JSON.stringify(data))
}

function exportFolder(data: any, folder: FolderApi, parent?: FolderApi) {
  const title = folder.title
  const folderData = {}
  if (parent !== undefined) {
    const parentTitle = parent.title
    data[parentTitle][title] = folderData
  } else {
    data[title] = folderData
  }
  let added = 0
  folder.children.forEach((child: BladeApi<BladeController<View>>) => {
    const label = child['label']
    switch (child.constructor.name) {
      case 'ButtonApi':
      case 'MonitorBindingApi':
        // skip
        break
      case 'FolderApi':
        exportFolder(data, child as FolderApi, folder)
        break
      case 'ListApi':
        folderData[label] = child['value']
        added++
        break
      default:
      case 'InputBindingApi':
        let value = child['value'] !== undefined ? child['value'] : child.controller_['binding']['value'].rawValue
        if (value instanceof Image) {
          value = value.src
        }
        folderData[label] = value
        added++
        break
    }
  })

  if (added === 0) {
    if (parent !== undefined) {
      const parentTitle = parent.title
      delete data[parentTitle][title]
    }
  }
}

function initDebug() {
  if (!IS_DEV) return
  if (gui === undefined) {
    gui = new Pane()
    gui.element.parentElement.style.maxHeight = '100%'
    gui.element.parentElement.style.overflow = 'hidden auto'
    gui.element.parentElement.style.position = 'fixed'
    gui.element.parentElement.style.zIndex = '10000000'
    gui.registerPlugin(EssentialsPlugin)
    gui.registerPlugin(TweakpaneImagePlugin)

    core = gui.addFolder({
      title: 'GUI',
      expanded: false,
    })

    // Presets
    const folder = debugFolder('Presets', false, core)
    const preset = { value: '{}' }
    const presetPane = debugInput(folder, preset, 'value', {
      label: 'Preset',
    })
    debugButton(folder, 'Import', () => {
      try {
        const data = JSON.parse(preset.value)
        importPreset(data)
      } catch (err) {
        console.warn('Could not parse the preset text, please send directly to a dev')
        console.log(err)
      }
    })
    debugButton(folder, 'Export', () => {
      const exported = exportPreset()
      preset.value = JSON.stringify(exported)
      presetPane.refresh()
      console.log(preset.value)
    })
    debugButton(folder, 'Export Folders', () => {
      exportFolders()
    })
  }
}

export function disposeDebug() {
  if (!IS_DEV) return
  if (gui !== undefined) {
    gui.dispose()
    core = undefined
    gui = undefined
    folders = {}
    panes = {}
    uuids.reset()
  }
}

export function debugFolder(
  name: string,
  expanded = false,
  parent: FolderApi | undefined = undefined,
  props: any = undefined,
) {
  if (!IS_DEV) return
  initDebug()
  // If a folder with the same name already exists, return that folder
  if (folders[name]) {
    return folders[name]
  }

  const usedGUI = parent !== undefined ? parent : core
  const folder = usedGUI.addFolder({
    title: name,
    expanded,
    ...props,
  })
  folder.uuid = nextPaneUUID('folder')
  folders[name] = folder
  return folders[name]
}

export function removeFolder(name: string) {
  if (!IS_DEV) return
  initDebug()
  const folder = folders[name]
  if (!folder) return
  gui.remove(folder)
  delete folders[name]
}

/**
 * Adds a button
 * @param folder An optional folder
 * @param label The button's label
 * @param callback The callback function
 * @returns The created GUI
 */
export function debugButton(folder: FolderApi | undefined, label: string, callback: () => void): ButtonApi {
  if (!IS_DEV) return
  initDebug()
  const _gui = folder !== undefined ? folder : core
  const btn = _gui.addButton({
    title: label,
  })
  btn.on('click', callback)
  return btn
}

/**
 * A color to debug
 * @param folder An optional folder
 * @param obj The object with the value
 * @param value The value you want to modify/listen to
 * @param props Optional predefined options
 * @returns The created GUI
 */
export function debugColor(
  folder: FolderApi | undefined,
  obj: any,
  value: string,
  props?: any,
): InputBindingApi<unknown, any> {
  if (!IS_DEV) return
  initDebug()
  const usedGUI = folder !== undefined ? folder : core
  let object = obj
  let key = value
  const threeColor = obj[value]['isColor'] === true
  if (threeColor) {
    const settings = {
      color: {
        r: clampColor(obj[value]['r'] * 255),
        g: clampColor(obj[value]['g'] * 255),
        b: clampColor(obj[value]['b'] * 255),
      },
    }
    object = settings
    key = 'color'
  }
  const label = props !== undefined && props['label'] ? props['label'] : value
  const added = usedGUI.addInput(object, key, { label })
  added.uuid = nextPaneUUID('color')
  added.on('change', (evt) => {
    if (threeColor) {
      obj[value].setRGB(evt.value.r / 255, evt.value.g / 255, evt.value.b / 255)
    }
    if (props !== undefined && props['onChange'] !== undefined) {
      props['onChange'](evt.value)
    }
  })
  panes[added.uuid] = added
  return added
}

/**
 * A list of items
 * @param folder An optional folder
 * @param label The option's label
 * @param options The array of options
 * @param callback The callback function
 * @returns The created GUI
 */
export function debugOptions(
  folder: FolderApi | undefined,
  label: string,
  options: Array<any>,
  callback: (value: any) => void,
  defaultValue?: any,
): BladeApi<BladeController<View>> {
  if (!IS_DEV) return
  initDebug()
  const usedGUI = folder !== undefined ? folder : core
  const added = usedGUI.addBlade({
    view: 'list',
    label: label,
    options: options,
    value: defaultValue !== undefined ? defaultValue : options[0].value,
  })
  added.uuid = nextPaneUUID('options')
  added.on('change', (evt: any) => {
    callback(evt.value)
  })
  panes[added.uuid] = added
  return added
}

/**
 * An object to debug
 * @param folder An optional folder
 * @param obj The object with the value
 * @param value The value you want to modify/listen to
 * @param props Optional predefined options
 * @returns The created GUI
 */
export function debugInput(
  folder: FolderApi | undefined,
  obj: any,
  value: string,
  props?: any,
): InputBindingApi<unknown, any> {
  if (!IS_DEV) return
  initDebug()
  const usedGUI = folder !== undefined ? folder : core
  const properties = {}
  if (props !== undefined) {
    for (const i in props) {
      if (i !== 'onChange') {
        properties[i] = props[i]
      }
    }
  }
  const added = usedGUI.addInput(obj, value, properties)
  const type = props !== undefined && props.panelType !== undefined ? props.panelType : 'input'
  added.uuid = nextPaneUUID(type)
  if (props !== undefined && props['onChange'] !== undefined) {
    added.on('change', (evt) => {
      props['onChange'](evt.value)
    })
  }
  panes[added.uuid] = added
  return added
}

/**
 * An object to debug
 * @param folder An optional folder
 * @param obj The object with the value
 * @param value The value you want to modify/listen to
 * @param props Optional predefined options
 * @returns The created monitor
 */
export function debugMonitor(
  folder: FolderApi | undefined,
  obj: any,
  value: string,
  props?: any,
): MonitorBindingApi<any> {
  if (!IS_DEV) return
  initDebug()
  const usedGUI = folder !== undefined ? folder : core
  return usedGUI.addMonitor(obj, value, props)
}

export function debugScalar(
  folder: FolderApi | undefined,
  label: string,
  defaultValue: number,
  obj: any,
  property: string,
  props: any = undefined,
) {
  const scale = { value: defaultValue }
  debugInput(folder, scale, 'value', {
    label,
    onChange: (value: number) => {
      obj[property].x = value
      obj[property].y = value
      if (obj[property].z !== undefined) {
        obj[property].z = value
      }
    },
    ...props,
  })
}

export function debugImage(folder: FolderApi | undefined, label: string, props: any) {
  const gui = folder !== undefined ? folder : this.gui
  const hasImage = props.image !== undefined
  const params = {}
  let type = 'placeholder'
  if (hasImage) {
    type = 'image'
    params['image'] = props.image
  } else {
    params['placeholder'] = 'placeholder'
  }
  const added = gui.addInput(params, type, {
    view: 'input-image',
    label: label,
    ...props,
  })
  added.uuid = nextPaneUUID('image')
  if (props !== undefined && props.onChange !== undefined) {
    let changed = false
    added.on('change', (evt) => {
      if (changed) {
        if (props.texture) {
          const texture = new Texture(evt.value)
          texture.wrapS = RepeatWrapping
          texture.wrapT = RepeatWrapping
          texture.needsUpdate = true
          // TODO: Assign original filename somehow
          texture.userData = { url: evt.value.src }
          props.onChange(texture)
        } else {
          props.onChange(evt.value)
        }
      }
      changed = true
    })
  }
  panes[added.uuid] = added
  return added
}

export function debugTexture(folder: FolderApi | undefined, label: string, texture: Texture, props: any) {
  if (texture.source.data instanceof ImageBitmap) {
    const json = texture.source.toJSON(undefined)
    const img = new Image()
    img.src = json.url
    const params = {
      image: img,
      texture: true,
      onChange: (texture: Texture) => {
        texture.flipY = false
        texture.needsUpdate = true
        props.onChange(texture)
      },
    }
    if (props['imageFit'] !== undefined) params['imageFit'] = props['imageFit']
    return debugImage(folder, label, params)
  }
  return undefined
}

function jsonName(name: string): string {
  return name.replace(/\s/g, '')
}

function importPreset(preset) {
  for (const i in preset) {
    const value = preset[i]
    const pane = panes[i]
    const type = i.split('_')[0]
    if (type === 'input') {
      const target = pane.controller_.binding.target
      switch (typeof value) {
        case 'boolean':
        case 'number':
          target.write(value)
          pane.refresh()
          break
        case 'object':
          if (target.obj_[target.key_]['x'] !== undefined) {
            target.obj_[target.key_].x = value.x
            target.obj_[target.key_].y = value.y
            target.obj_[target.key_].z = value.z
          } else {
            target.write(value)
          }
          pane.refresh()
          break
        case 'string':
          const isBlob = value.search('blob:') > -1
          if (value.search('http') > -1) {
            const currentValue = pane.controller_.valueController.value.rawValue.src
            if (value !== currentValue) {
              loadImageElement(value).then((img) => {
                pane.controller_.valueController.handleImage(img)
                pane.emitter_.emit('change', {
                  event: new TpChangeEvent(pane, img, pane.controller_.binding.target.presetKey_),
                })
              })
            }
          } else if (!isBlob) {
            target.write(value)
            pane.refresh()
          } else {
            console.log('"Blobs" are not exported, please define image name')
          }
          break
      }
    } else if (type === 'color') {
      const target = pane.controller_.binding.target
      target.write({
        r: value[0],
        g: value[1],
        b: value[2],
      })
      pane.refresh()
    } else {
      pane.value = value
    }
  }
}

function exportPreset() {
  const preset = {}
  const cycle = (folder, parent) => {
    const folderContainer = {}
    folder.children.forEach((item) => {
      if (item instanceof FolderApi) {
        cycle(item, parent)
      } else if (item.uuid !== undefined) {
        const type = item.uuid.split('_')[0]
        if (type === 'input') {
          const binding = item.controller_.binding.target
          let value = binding['obj_'][binding['key_']] as any
          if (value instanceof Image) {
            value = value.src
          } else if (typeof value === 'number') {
            value = Number(value.toFixed(4))
          } else if (value['x'] !== undefined && value['y'] !== undefined && value['z'] !== undefined) {
            value = {
              x: Number(value.x.toFixed(3)),
              y: Number(value.y.toFixed(3)),
              z: Number(value.z.toFixed(3)),
            }
          }

          if (item['uuid'] !== 'input_0') {
            preset[item['uuid']] = value
          }
        } else if (type === 'options') {
          preset[item['uuid']] = item.value
        } else if (type === 'color') {
          const value = item.controller_.binding.value.rawValue.comps_
          preset[item['uuid']] = [clampColor(value[0]), clampColor(value[1]), clampColor(value[2]), value[3]]
        }
      }
    })
    if (Object.keys(folderContainer).length === 0 && folder.title !== undefined) {
      delete parent[jsonName(folder.title)]
    }
  }
  cycle(gui, preset)
  return preset
}
