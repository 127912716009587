import { useRef } from 'react'
import { rem } from 'polished'
import dynamic from 'next/dynamic'
import { chakra, IconProps } from '@chakra-ui/react'
import { MotionList, MotionListItem } from 'components/Motion'
import Button from 'components/buttons/Button'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { SOCIAL_KEYS } from 'constants/design'
import colors from 'constants/colors'
import { ContentfulGraphSocialLink } from '../contentful/types'

interface SocialsProps {
  items: ContentfulGraphSocialLink[]
  className?: string
}

const IconInstagram = dynamic<IconProps>(() => import('components/common/Icons').then((mod) => mod.IconInstagram))
const IconLinkedin = dynamic<IconProps>(() => import('components/common/Icons').then((mod) => mod.IconLinkedin))
const IconFacebook = dynamic<IconProps>(() => import('components/common/Icons').then((mod) => mod.IconFacebook))
const IconTwitter = dynamic<IconProps>(() => import('components/common/Icons').then((mod) => mod.IconTwitter))
const IconVimeo = dynamic<IconProps>(() => import('components/common/Icons').then((mod) => mod.IconVimeo))

const Socials = ({ className, items }: SocialsProps) => {
  const refList = useRef()
  const entry = useIntersectionObserver(refList, {})

  return (
    !!items?.length && (
      <MotionList
        className={className}
        display="flex"
        ml={{ base: rem(-14) }}
        ref={refList}
        variants={variantsList}
        animate={entry?.isIntersecting ? 'animate' : 'initial'}
      >
        {items.map((item) => (
          <MotionListItem mr={{ base: rem(3) }} variants={variantsListItem} key={item?.sys?.id}>
            <Button
              w={{ base: `${44 / 16}em` }}
              px={{ base: rem(11) }}
              variants={variantsButton}
              whileHover="hover"
              href={item?.url}
            >
              {getIcon(item?.type as keyof typeof SOCIAL_KEYS)}
            </Button>
          </MotionListItem>
        ))}
      </MotionList>
    )
  )
}

const variantsList = {
  animate: {
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const variantsListItem = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
}

const variantsButton = {
  hover: {
    color: colors.blue_1,
  },
}

function getIcon(id: keyof typeof SOCIAL_KEYS) {
  switch (id) {
    case SOCIAL_KEYS.instagram:
      return <IconInstagram />
    case SOCIAL_KEYS.linkedin:
      return <IconLinkedin />
    case SOCIAL_KEYS.facebook:
      return <IconFacebook />
    case SOCIAL_KEYS.twitter:
      return <IconTwitter />
    case SOCIAL_KEYS.vimeo:
      return <IconVimeo />
  }
}

export default chakra(Socials)
