// Libs
import { useEffect, useRef, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Group, MathUtils, Texture } from 'three'
// Constants
import * as svg from 'constants/svg'
// Utils
import { Settings } from 'hooks/useAssetLoader'
import { svgToTexture } from '../../utils/'
import { debugFolder, debugInput } from '../../utils/debug'
import { app, dispatcher, Events } from '../../global'
// Component
import Row from './Row'
import useTouchPosition from 'hooks/useTouchPosition'
import useMousePosition from 'hooks/useMousePosition'

let topRow
let bottomRow
let rows = false

export default function FooterComponent() {
  const groupRef = useRef<Group>()
  const [texturesReady, setTexturesReady] = useState(false)
  const [textures] = useState(new Map<string, Texture>())
  const initialScale = Settings.mobile ? 0.002 : 0.0033
  const { camera } = useThree()

  const createTextures = () => {
    const toFind = 'viewBox="0 0 '
    let texturesCompleted = 0
    let totalSVG = 0
    for (const i in svg) {
      ++totalSVG
      const data = svg[i] as string
      const start = data.indexOf(toFind) + toFind.length
      const end = data.indexOf('"', start)
      const sizes = data.substring(start, end).split(' ')
      const width = Number(sizes[0])
      const height = Number(sizes[1])
      svgToTexture(data, width, height).then((value: Texture) => {
        value.name = i
        textures.set(i, value)
        texturesCompleted++
        if (texturesCompleted === totalSVG) {
          setTexturesReady(true)
          updateURL()
        }
      })
    }
  }

  const initDebug = () => {
    const container = groupRef.current as Group
    const parentFolder = debugFolder('Scene')
    const footerFolder = debugFolder('Footer', false, parentFolder)
    const settings = {
      scale: initialScale,
      visible: true,
    }
    debugInput(footerFolder, settings, 'scale', {
      min: 0,
      max: 0.01,
      onChange: (value: number) => {
        container.scale.setScalar(value)
      },
    })
    debugInput(footerFolder, settings, 'visible', {
      onChange: (value: boolean) => {
        console.log(container)
        topRow.container.visible = value
        bottomRow.container.visible = value
      },
    })
  }

  const updateURL = () => {
    const container = groupRef.current as Group

    // Remove previous items
    if (topRow) topRow.dispose()
    if (bottomRow) bottomRow.dispose()
    topRow = undefined
    bottomRow = undefined
    rows = false

    // Create new items
    const topTextures: Array<Texture> = []
    const bottomTextures: Array<Texture> = []
    switch (app.currentPage) {
      default:
      case '':
        topTextures.push(textures.get('Strategy'))
        topTextures.push(textures.get('Design'))
        topTextures.push(textures.get('Production'))
        bottomTextures.push(textures.get('Walk'))
        bottomTextures.push(textures.get('Run'))
        bottomTextures.push(textures.get('Pioneer'))
        break
      case 'careers':
        topTextures.push(textures.get('Strategic'))
        topTextures.push(textures.get('FutureProof'))
        topTextures.push(textures.get('Powerful'))
        bottomTextures.push(textures.get('Craft'))
        bottomTextures.push(textures.get('Creativity'))
        bottomTextures.push(textures.get('Commitment'))
        break
      case 'contact':
        topTextures.push(textures.get('Digital'))
        topTextures.push(textures.get('Content'))
        topTextures.push(textures.get('Experiential'))
        bottomTextures.push(textures.get('Dreamers'))
        bottomTextures.push(textures.get('Makers'))
        bottomTextures.push(textures.get('Stewards'))
        break
      case 'what-we-do':
        topTextures.push(textures.get('Narrative'))
        topTextures.push(textures.get('Build'))
        topTextures.push(textures.get('Experience'))
        bottomTextures.push(textures.get('Sweat'))
        bottomTextures.push(textures.get('Passion'))
        bottomTextures.push(textures.get('Keyframes'))
        break
      case 'who-we-are':
        topTextures.push(textures.get('Strategy'))
        topTextures.push(textures.get('Production'))
        topTextures.push(textures.get('Technology'))
        bottomTextures.push(textures.get('BeDifferent'))
        bottomTextures.push(textures.get('BeBold'))
        bottomTextures.push(textures.get('BeWild'))
        break
      case 'work':
        topTextures.push(textures.get('Design'))
        topTextures.push(textures.get('Code'))
        topTextures.push(textures.get('Content'))
        bottomTextures.push(textures.get('Intuitive'))
        bottomTextures.push(textures.get('Engaging'))
        bottomTextures.push(textures.get('Shareable'))
        break
    }

    topRow = new Row('top', camera, -1, topTextures.concat(topTextures))
    topRow.setPosition(200, -0.7)
    container.add(topRow.container)

    bottomRow = new Row('bottom', camera, 1, bottomTextures.concat(bottomTextures))
    bottomRow.setPosition(-200, 0.7)
    container.add(bottomRow.container)
    rows = true
  }

  useEffect(() => {
    initDebug()
    if (!texturesReady) createTextures()
    dispatcher.addEventListener(Events.URL_UPDATE, updateURL)
    return () => {
      dispatcher.removeEventListener(Events.URL_UPDATE, updateURL)
    }
  }, [])

  const mousePos = Settings.mobile ? useTouchPosition({ isEnabled: true }) : useMousePosition({ isEnabled: true })
  useFrame((_, delta) => {
    const group = groupRef.current as Group
    group.visible = app.footerVisible
    if (group.visible && rows) {
      topRow.update(delta, mousePos, _.raycaster.intersectObjects(topRow.container.children))
      bottomRow.update(delta, mousePos, _.raycaster.intersectObjects(bottomRow.container.children))
    }
  })

  return <group ref={groupRef} rotation={[0, 0, MathUtils.degToRad(2)]} scale={[initialScale, initialScale, 1]} />
}
