import { rem } from 'polished'

import { BREAKPOINT_KEYS, BreakpointKeysInterface } from 'constants/breakpoints'

type GridInterface = (
  bp: keyof BreakpointKeysInterface,
  columns: number,
  columnsParent: number,
  gridBreakingExtraWidth?: number,
) => string

type OffsetInterface = (
  bp: keyof BreakpointKeysInterface,
  columns: number,
  columnsParent: number,
  hasExtraGap?: boolean,
) => string

const GRID_COLUMNS_SM = 4
const GRID_COLUMNS_XL = 12
export const GRID_GAP_SM = 20
export const GRID_GAP_XL = 20

export const grid: GridInterface = (bp, columns, columnsParent, gridBreakingExtraWidth = 0) => {
  let gridColumns = 0
  let gridGap = 0

  switch (bp) {
    case BREAKPOINT_KEYS.sm:
      gridColumns = GRID_COLUMNS_SM
      gridGap = GRID_GAP_SM
      break

    default:
      gridColumns = GRID_COLUMNS_XL
      gridGap = GRID_GAP_XL
      break
  }

  const columnPct = (100 / gridColumns) * columns * (gridColumns / columnsParent)
  const columnPx = gridGap * ((columnsParent - columns) / columnsParent)

  return `calc(${columnPct}% - ${rem(columnPx - gridBreakingExtraWidth)})`
}

export const offset: OffsetInterface = (bp, columns, columnsParent, hasExtraGap = false) => {
  let gridColumns = 0
  let gridGap = 0

  switch (bp) {
    case BREAKPOINT_KEYS.sm:
      gridColumns = GRID_COLUMNS_SM
      gridGap = GRID_GAP_SM
      break

    default:
      gridColumns = GRID_COLUMNS_XL
      gridGap = GRID_GAP_XL
      break
  }

  const columnPct = (100 / gridColumns) * columns * (gridColumns / columnsParent)
  const columnPx = gridGap / (columnsParent / columns) + (hasExtraGap ? gridGap : 0)

  return `calc(${columnPct}% + ${rem(columnPx)})`
}

export function pct(x: number, y: number) {
  return `${(x / y) * 100}%`
}

export function fluidType(minVw: string, maxVw: string, minFontSize: number, maxFontsize: number) {
  function stripUnit(value: string) {
    return parseInt(value, 10)
  }

  return `calc(${minFontSize}px + ${maxFontsize - minFontSize} * ((100vw - ${minVw}) / ${
    stripUnit(maxVw) - stripUnit(minVw)
  }))`
}

export function prefixZero(index: number, offset = 0) {
  const max = 10 - offset

  return (index < max ? '0' : '') + `${index + offset}`
}
