import { ShaderMaterial, Texture, Uniform } from 'three'
import glsl from 'glslify'
import vertex from '../shaders/default.vert'
import fragment from '../shaders/halo.frag'
import { debugFolder, debugInput } from '../utils/debug'
import { IS_DEV } from '../../constants'

export default class HaloMaterial extends ShaderMaterial {
  constructor() {
    const params = {
      uniforms: {
        map: new Uniform(null),
        warp: new Uniform(0.85),
      },
      vertexShader: glsl(vertex),
      fragmentShader: glsl(fragment),
      type: 'Halo',
    }
    super(params)
    if (IS_DEV) {
      const parentFolder = debugFolder('Post')
      const folder = debugFolder('Halo', false, parentFolder)
      debugInput(folder, this.uniforms['warp'], 'value', {
        label: 'warp',
        min: 0,
        max: 1,
        step: 0.01,
      })
    }
  }

  get map(): Texture {
    return this.uniforms.map.value
  }

  set map(value: Texture) {
    this.uniforms.map.value = value
  }

  get warp(): number {
    return this.uniforms.warp.value
  }

  set warp(value: number) {
    this.uniforms.warp.value = value
  }
}
