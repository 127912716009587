import { isNumber } from 'lodash'
import { useCallback, useState, useLayoutEffect, useEffect } from 'react'

const useIsomorphicLayoutEffect: (cb: () => void, deps: any[]) => void =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect

const useMediaQuery = (query: number): boolean => {
  const formatQuery = isNumber(query) ? `(max-width: ${query}px)` : query
  const [targetReached, setTargetReached] = useState(false)

  const updateTarget = useCallback((e) => setTargetReached(!!e.matches), [])

  useIsomorphicLayoutEffect(() => {
    const mql: MediaQueryList = window.matchMedia(formatQuery)
    mql.addEventListener('change', updateTarget)
    if (mql.matches) setTargetReached(true)

    return () => mql.removeEventListener('change', updateTarget)
  }, [])

  return targetReached
}

export default useMediaQuery
