// Libs
import React, { useRef, useEffect, useCallback, useState } from 'react'
import { Group } from 'three'
// Hooks
import useScrollTicker from 'hooks/useScrollTicker'
import { Settings } from 'hooks/useAssetLoader'
// Utils
import { clamp } from '../utils/math'
// Components
import Astronaut from './components/Astronaut'
import FooterComponent from './components/footer'
import Lights from './components/Lights'
import Post from './components/Post'
import Scene from './components/Scene'
// Controllers
import CameraController from './controllers/CameraController'
import CursorHandler from './controllers/CursorHandler'
import KeyController from './controllers/KeyController'
// Global
import { Animations, app, dispatcher, Events, tweener } from './global'
import { useFrame } from '@react-three/fiber'

const IS_DEV = process.env.NODE_ENV === 'development'

export default function ThreeScene() {
  const groupRef = useRef<Group>()
  const [config] = useState({
    headerVisible: false,
    footerVisible: false,
  })

  const enterHeader = useCallback(() => {
    dispatcher.dispatchEvent({
      type: Events.ENTER_HEADER,
    })
  }, [])

  const enterFooter = useCallback(() => {
    dispatcher.dispatchEvent({
      type: Events.ENTER_FOOTER,
    })
  }, [])

  const onScroll = useCallback(() => {
    const group = groupRef.current as Group
    const scrolled = window.scrollY
    const isWWD = app.currentPage === 'what-we-do'
    const elementHeight = document.documentElement.clientHeight * (isWWD ? 2 : 1)
    const isHome = window.location.pathname.length < 2
    const footer = document.querySelector('footer')
    const footerMax = footer.offsetTop
    const footerMin = footerMax - elementHeight

    // Footer animation
    app.footerVisible = false
    app.headerVisible = false
    if (scrolled >= footerMin) {
      app.percent = clamp(0, 1, (scrolled - footerMin) / elementHeight)
      app.footerVisible = true
    } else if ((isHome || isWWD) && scrolled <= elementHeight) {
      app.percent = clamp(0, 1, scrolled / elementHeight)
      app.headerVisible = true
    }

    // Trigger events for entering header/footer
    if (config.headerVisible !== app.headerVisible && app.headerVisible === true) enterHeader()
    if (config.footerVisible !== app.footerVisible && app.footerVisible === true) enterFooter()
    config.headerVisible = app.headerVisible
    config.footerVisible = app.footerVisible

    if (group) {
      group.visible = app.footerVisible || app.headerVisible
    }
  }, [])

  useEffect(() => {
    onScroll()
    dispatcher.addEventListener(Events.URL_UPDATE, onScroll)

    if (IS_DEV) {
      let timer: any = setTimeout(() => {
        clearTimeout(timer)
        timer = undefined
        // Begin
        dispatcher.dispatchEvent({
          type: Events.ANIMATION,
          value: Animations.Intro,
        })
      }, 1000)
    }
    return () => {
      dispatcher.removeEventListener(Events.URL_UPDATE, onScroll)
    }
  }, [])

  useFrame((_, delta) => {
    tweener.update(delta)

    const location = window.location.pathname.substring(1)
    const primary = location.split('/')[0]
    if (primary !== app.currentPage) {
      app.currentPage = primary
      dispatcher.dispatchEvent({
        type: Events.URL_UPDATE,
        value: app.currentPage,
      })
    }
  })

  useScrollTicker(onScroll, true, [])

  return (
    <>
      <group ref={groupRef}>
        <Lights />
        <Scene />
        <Astronaut />
        <FooterComponent />
        <Post />
      </group>
      <CameraController />
      {Settings.mobile ? null : <CursorHandler />}
      {Settings.mobile ? null : <KeyController />}
    </>
  )
}
