import { AnimationClip, AnimationMixer, LoopOnce, LoopRepeat } from 'three'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import { debugFolder, debugOptions } from '../utils/debug'
import { tweener } from '../global'
import { IS_DEV } from '../../constants'

export default function GLTFPlayer(name: string, gltf: GLTF) {
  this.name = name
  this.actions = {}
  this.current = ''

  this.cameras = gltf.cameras
  this.scene = gltf.scene
  this.mixer = new AnimationMixer(this.scene)

  const initDebug = () => {
    const parentFolder = debugFolder('Character')
    const folder = debugFolder(`${this.name} Animation`, false, parentFolder)
    const animations = [
      {
        text: 'None',
        value: 'None',
      },
    ]
    for (const i in this.actions) {
      animations.push({
        text: i,
        value: i,
      })
    }
    debugOptions(folder, 'Animation', animations, (value: string) => {
      this.stopAll()
      if (value !== 'None') {
        this.play(value)
      }
    })
    // debugInput(folder, this, 'timeScale', {
    //   min: 0,
    //   max: 2,
    //   step: 0.01,
    // })
  }

  this.play = (name: string) => {
    const action = this.actions[name]
    if (action === undefined || this.current === name) return
    this.current = name
    action.weight = 1
    action.time = 0
    action.play()
  }

  this.stopAll = () => {
    this.current = ''
    for (const i in this.actions) {
      const action = this.actions[i]
      // this.setWeight(action, 0)
      action.stop()
    }
  }

  this.update = (delta: number) => {
    this.mixer.update(delta)
  }

  this.transitionTo = (to: string, duration: number, onComplete?: () => void) => {
    if (this.current === to) return // current animation, no transition
    if (this.current.length > 0) {
      const next = this.actions[to]
      next.enabled = true
      if (next.repetitions === 0) {
        next.time = 0
        next.play()
      }

      // Ease current out
      const current = this.actions[this.current]
      tweener.to(current, duration, {
        weight: 0,
        ease: [0.215, 0.61, 0.355, 1],
      })
      tweener.to(next, duration, {
        weight: 1,
        ease: [0.645, 0.045, 0.355, 1],
      })
      this.current = to
      if (onComplete !== undefined) {
        const duration = this.getDuration(to) * (1 / next.timeScale) * 0.9
        this.delay(duration).then(() => {
          // Check to see if the animation has changed
          if (this.current === to) onComplete()
        })
      }
    } else {
      this.play(to)
    }
  }

  this.setLoop = (name: string, repetitions = Infinity, mode = LoopRepeat) => {
    const action = this.actions[name]
    if (action === undefined) return
    if (repetitions > 0) {
      action.setLoop(mode, repetitions)
    } else {
      action.setLoop(LoopOnce, 0)
      // action.clampWhenFinished = true
    }
  }

  this.getDuration = (name: string): number => {
    const action = this.actions[name]
    if (action) {
      return action.getClip().duration
    }
    return -1
  }

  this.delay = (seconds: number): Promise<void> => {
    return new Promise((resolve) => {
      let timer: any = setTimeout(() => {
        clearTimeout(timer)
        timer = undefined
        resolve()
      }, seconds * 1000)
    })
  }

  this.setTimeScale = (name: string, timeScale: number): void => {
    const action = this.actions[name]
    if (action !== undefined) {
      action.timeScale = timeScale
    }
  }

  // Actions
  gltf.animations.forEach((animation: AnimationClip) => {
    const action = this.mixer.clipAction(animation)
    this.actions[animation.name] = action
    action.enabled = true
    action.weight = 0
    action.play()
  })

  if (IS_DEV) initDebug()
}
