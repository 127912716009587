import { useState } from 'react'
import { rem, rgba } from 'polished'
import { Box, Center } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'

import NavigationOverlay from 'components/navigation/NavigationOverlay'
import ButtonDots from 'components/buttons/ButtonDots'
import Button from 'components/buttons/Button'
import Logo from 'components/Logo'

import { useAppContext } from 'context/AppContext'

import { ContentfulGraphRoute, ContentfulGraphSocialLink } from 'contentful/types'
import { pct } from 'utils/designHelper'
import colors from 'constants/colors'

interface NavigationMobileProps {
  links: ContentfulGraphRoute[]
  socials: ContentfulGraphSocialLink[]
  isEnabled: boolean
}

const NavigationMobile = ({ links, socials, isEnabled }: NavigationMobileProps) => {
  const [isNavigationOverlayOpen, setIsNavigationOverlayOpen] = useState(false)

  const context = useAppContext()

  function handleClickButtonDots() {
    setIsNavigationOverlayOpen((prevState) => !prevState)
  }

  function handleClickButton() {
    setIsNavigationOverlayOpen(false)
  }

  return (
    <Center
      pos="fixed"
      top={0}
      pt={{ base: rem(26), xl: rem(62) }}
      zIndex="navigation"
      w="100%"
      pointerEvents="none"
      _before={{
        content: '""',
        pos: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        w: '100%',
        h: { base: rem(100), xl: rem(200) },
        background: `linear-gradient(${colors.blue_4} 20%, ${rgba(colors.blue_4, 0)})`,
        opacity: context?.isNavigationDarkTheme ? 0.6 : 0,
        transition: 'opacity 0.2s linear',
        pointerEvents: 'none',
      }}
    >
      <Box
        as="nav"
        display="flex"
        justifyContent="space-between"
        w={{ base: '100%', xl: pct(1308, 1440) }}
        px={{ base: rem(30), xl: 0 }}
      >
        <Button
          pos="relative"
          zIndex={2}
          w={{ base: rem(58), xl: rem(79) }}
          ml={{ xl: rem(15) }}
          href="/"
          pointerEvents={isEnabled ? 'auto' : 'none'}
          onClick={handleClickButton}
        >
          <Logo w="100%" h="100%" color="blue_1" />
        </Button>

        <AnimatePresence>
          {isNavigationOverlayOpen && (
            <NavigationOverlay
              zIndex={1}
              pointerEvents="auto"
              links={links}
              socials={socials}
              onClickButton={handleClickButton}
            />
          )}
        </AnimatePresence>

        <ButtonDots
          pos="relative"
          zIndex={1}
          top={rem(2)}
          mr={rem(-12)}
          pointerEvents="auto"
          isActive={isNavigationOverlayOpen}
          onClick={handleClickButtonDots}
          opacity={0}
          variants={variantsContainer}
          animate={isEnabled && 'animate'}
        />
      </Box>
    </Center>
  )
}

export default NavigationMobile

const variantsContainer = {
  animate: {
    opacity: 1,
  },
}
