import { useFrame, useThree } from '@react-three/fiber'
import { useEffect } from 'react'
import { debugFolder, debugInput, debugMonitor } from '../utils/debug'
import { app } from '../global'
import { Settings } from 'hooks/useAssetLoader'

let performanceDebugged = false
export default function DebugPerformance() {
  const { gl } = useThree()

  useEffect(() => {
    if (!performanceDebugged) {
      performanceDebugged = true

      const speed = 1000 / 10
      gl.info.autoReset = false

      const appFolder = debugFolder('App', false, undefined, {
        index: 0,
      })
      debugInput(appFolder, Settings, 'quality')
      debugInput(appFolder, Settings, 'fps')
      debugMonitor(appFolder, app, 'percent')
      debugMonitor(appFolder, app, 'headerVisible')
      debugMonitor(appFolder, app, 'footerVisible')
      debugMonitor(appFolder, app, 'currentPage')

      const perfFolder = debugFolder('Performance', false, undefined, {
        index: 1,
      })
      debugMonitor(perfFolder, gl.info.render, 'calls', {
        interval: speed,
        label: 'DrawCalls',
      })
      debugMonitor(perfFolder, gl.info.render, 'lines', {
        interval: speed,
        label: 'Lines',
      })
      debugMonitor(perfFolder, gl.info.render, 'points', {
        interval: speed,
        label: 'Points',
      })
      debugMonitor(perfFolder, gl.info.render, 'triangles', {
        interval: speed,
        label: 'Tris',
      })
      debugMonitor(perfFolder, gl.info.memory, 'geometries', {
        interval: speed,
        label: 'Geom',
      })
      debugMonitor(perfFolder, gl.info.memory, 'textures', {
        interval: speed,
        label: 'Textures',
      })
      debugMonitor(perfFolder, gl.info.programs, 'length', {
        interval: speed,
        label: 'Programs',
      })
    }

    return () => {
      performanceDebugged = false
    }
  }, [])

  useFrame(() => {
    gl.info.reset()
  })

  return <></>
}
