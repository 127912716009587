import { Color, ShaderMaterial, Texture, Uniform, Vector2 } from 'three'
import glsl from 'glslify'
import vertex from '../shaders/default.vert'
import fragment from '../shaders/footerUI.frag'
import { tweener } from '../global'

const defaultColor = new Color(0xffffff)
const highlightColor = new Color(0x00c0f0)

export default class FooterUIMaterial extends ShaderMaterial {
  constructor(texture: Texture) {
    const width = texture.image.width
    const height = texture.image.height
    const params = {
      transparent: true,
      uniforms: {
        diffuse: new Uniform(defaultColor.clone()),
        borderOpacity: new Uniform(0),
        borderThickness: new Uniform(2),
        map: new Uniform(texture),
        imgSize: new Uniform(new Vector2(width, height)),
      },
      vertexShader: glsl(vertex),
      fragmentShader: glsl(fragment),
      type: 'FooterUI',
    }
    super(params)
  }

  rollOver() {
    tweener.to(this.uniforms.borderOpacity, 0.25, {
      value: 1,
      onUpdate: (value: number) => {
        this.uniforms.diffuse.value.lerpColors(defaultColor, highlightColor, value)
      },
    })
  }

  rollOut() {
    tweener.to(this.uniforms.borderOpacity, 0.25, {
      value: 0,
      onUpdate: (value: number) => {
        this.uniforms.diffuse.value.lerpColors(highlightColor, defaultColor, value)
      },
    })
  }

  // Getters / Setters

  get thickness(): number {
    return this.uniforms['borderThickness'].value
  }

  set thickness(value: number) {
    this.uniforms['borderThickness'].value = value
  }
}
