import { rem } from 'polished'
import { useEffect } from 'react'
import { chakra } from '@chakra-ui/react'
import { useAnimation } from 'framer-motion'

import Button from 'components/buttons/Button'
import { MotionBox } from 'components/Motion'

interface ButtonDotsProps {
  isActive?: boolean
  onClick?: () => void
  className?: string
}

const ButtonDots = ({ className, isActive, onClick, ...props }: ButtonDotsProps) => {
  const animationDotOne = useAnimation()
  const animationDotTwo = useAnimation()
  const animationDotThree = useAnimation()
  const animationLineOne = useAnimation()
  const animationLineTwo = useAnimation()

  useEffect(() => {
    const sequence = async () => {
      if (isActive) {
        animationDotOne.start({ scale: 0.5, x: rem(8) })
        animationDotTwo.start({ scale: 0.5 })
        await animationDotThree.start({ scale: 0.5, x: rem(-8) })

        await animationLineOne.start({ scaleX: 1 })
        animationDotOne.set({ opacity: 0 })
        animationDotTwo.set({ opacity: 0 })
        animationDotThree.set({ opacity: 0 })

        animationLineTwo.set({ scaleX: 1 })
        animationLineOne.start({ rotate: 45 })
        animationLineTwo.start({ rotate: -45 })

        return
      }

      animationLineOne.start({ rotate: 0 })
      await animationLineTwo.start({ rotate: 0 })

      animationDotOne.set({ opacity: 1 })
      animationDotTwo.set({ opacity: 1 })
      animationDotThree.set({ opacity: 1 })

      animationLineTwo.set({ scaleX: 0 })
      await animationLineOne.start({ scaleX: 0 })

      animationDotTwo.start({ scale: 1 })
      animationDotOne.start({ scale: 1, x: 0 })
      animationDotThree.start({ scale: 1, x: 0 })
    }

    sequence()

    return () => {
      animationDotOne.stop()
      animationDotTwo.stop()
      animationDotThree.stop()
      animationLineOne.stop()
      animationLineTwo.stop()
    }
  }, [isActive, animationDotOne, animationDotTwo, animationDotThree, animationLineOne, animationLineTwo])

  return (
    <Button
      className={className}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      p={rem(12)}
      onClick={onClick}
      {...props}
    >
      <Dot mr={rem(4)} animate={animationDotOne} />
      <Dot mr={rem(4)} animate={animationDotTwo} />
      <Dot animate={animationDotThree} />

      <MotionBox as="span" pos="absolute" w={rem(24)} h={rem(2)} bgColor="white" animate={animationLineOne} />
      <MotionBox as="span" pos="absolute" w={rem(24)} h={rem(2)} bgColor="white" animate={animationLineTwo} />
    </Button>
  )
}

export default chakra(ButtonDots)

function Dot({ ...props }) {
  return (
    <MotionBox
      as="span"
      pos="relative"
      w={rem(4)}
      h={rem(4)}
      mr={rem(4)}
      borderRadius="50%"
      bgColor="white"
      _before={{
        content: '""',
        pos: 'absolute',
        top: rem(-1),
        left: rem(-1),
        zIndex: -1,
        w: rem(6),
        h: rem(6),
        borderRadius: '50%',
        bgColor: 'blue_4',
        filter: 'blur(5px)',
      }}
      {...props}
    />
  )
}
