import { css, Global } from '@emotion/react'

import GtAlpinaThinCondensedWoff2 from '../public/fonts/GT-Alpina-Fine-Condensed-Thin.woff2'
import GtAlpinaThinCondensedWoff from '../public/fonts/GT-Alpina-Fine-Condensed-Thin.woff'
import EsKlarheitKurrentLightWoff2 from '../public/fonts/ESKlarheitKurrent-Light.woff2'
import EsKlarheitKurrentLightWoff from '../public/fonts/ESKlarheitKurrent-Light.woff'
import EsKlarheitGroteskBookWoff2 from '../public/fonts/ESKlarheitGrotesk-Book.woff2'
import EsKlarheitGroteskBookWoff from '../public/fonts/ESKlarheitGrotesk-Book.woff'
import ABCMarfaMonoMediumWoff2 from '../public/fonts/ABCMarfaMono-Medium.woff2'
import ABCMarfaMonoMediumWoff from '../public/fonts/ABCMarfaMono-Medium.woff'

import { BASE_PATH } from '../constants'
import {
  ABC_MARFA_MONO_MEDIUM,
  ES_KLARHEIT_GROTESK_BOOK,
  ES_KLARHEIT_KURRENT_LIGHT,
  GT_ALPINA_FINE_CONDENSED,
} from 'constants/fonts'

const fontList = [
  {
    family: GT_ALPINA_FINE_CONDENSED,
    woff2: BASE_PATH + GtAlpinaThinCondensedWoff2,
    woff: BASE_PATH + GtAlpinaThinCondensedWoff,
    style: 'normal',
    weight: 200,
  },
  {
    family: ES_KLARHEIT_KURRENT_LIGHT,
    woff2: BASE_PATH + EsKlarheitKurrentLightWoff2,
    woff: BASE_PATH + EsKlarheitKurrentLightWoff,
    style: 'normal',
    weight: 300,
  },
  {
    family: ES_KLARHEIT_GROTESK_BOOK,
    woff2: BASE_PATH + EsKlarheitGroteskBookWoff2,
    woff: BASE_PATH + EsKlarheitGroteskBookWoff,
    style: 'normal',
    weight: 400,
  },
  {
    family: ABC_MARFA_MONO_MEDIUM,
    woff2: BASE_PATH + ABCMarfaMonoMediumWoff2,
    woff: BASE_PATH + ABCMarfaMonoMediumWoff,
    style: 'normal',
    weight: 500,
  },
]

const fonts = fontList.map(({ family, woff2, woff, style, weight }) => {
  return `@font-face {
      font-family: "${family}";
      src: url(${woff2}) format('woff2'), url(${woff}) format('woff');
      font-style: ${style};
      font-weight: ${weight};
    }`
})

let fontStyles = ''
fonts.forEach((f) => (fontStyles += f))

const Fonts = () => (
  <Global
    styles={css`
      ${fontStyles}
    `}
  />
)

export default Fonts
