import { useCallback } from 'react'

import { useThreeDispatchContext } from 'context/ThreeContext'
import useAssetLoader from 'hooks/useAssetLoader'

import { HOME_ASSETS } from 'constants/assets'
import { EntryTypes } from 'contentful/types'

interface ThreeLoaderProps {
  children: React.ReactNode
  isEnabled: boolean
}

const PAGE_ID = EntryTypes.HOME_PAGE

const ThreeLoader = ({ children, isEnabled }: ThreeLoaderProps) => {
  const dispatcher = useThreeDispatchContext()

  const onLoadStart = useCallback(() => {
    //
  }, [])

  const onLoadProgress = useCallback((p: number) => {
    dispatcher.changeLoadProgress(Math.round(p * 100))
  }, [])

  const onLoadComplete = useCallback(() => {
    dispatcher.changeLoadState('LOAD_COMPLETE')
  }, [])

  useAssetLoader({
    manifest: HOME_ASSETS,
    loaderId: PAGE_ID,
    onLoadStart,
    onLoadProgress,
    onLoadComplete,
    isEnabled,
  })

  return <>{children}</>
}

export default ThreeLoader
