import { MirroredRepeatWrapping, Texture, Uniform, Vector2, Vector3, WebGLRenderer, WebGLRenderTarget } from 'three'
import { ImprovedNoise } from 'three/examples/jsm/math/ImprovedNoise'
import { BlendFunction, Effect } from 'postprocessing'
import { getAsset } from 'hooks/useAssetLoader'
import { clamp, map } from 'utils/math'
import glsl from 'glslify'
import fragment from '../shaders/composite.frag'
import HaloMaterial from '../materials/HaloMaterial'
import { RTT } from '../utils'
import { KawaseBlurPass } from 'postprocessing'
import { app } from '../global'
import { debugFolder, debugInput } from '../utils/debug'

const perlin = new ImprovedNoise()
const config = {
  range: new Vector2(0.02, 0.48),
}

// Halo
let halo = undefined
let haloRTT = undefined
const haloTarget = new WebGLRenderTarget(512, 512, {
  generateMipmaps: true,
  depthBuffer: false,
})
let blur = undefined
const blurTarget = new WebGLRenderTarget(512, 512, {
  depthBuffer: false,
})

export default class CompositeEffect extends Effect {
  constructor() {
    const overlay = getAsset('specs', 'homePage').data as Texture
    const logo = getAsset('w-vector', 'homePage').data as Texture
    const wwdFBM = getAsset('wwd-overlay', 'homePage').data as Texture
    wwdFBM.wrapS = MirroredRepeatWrapping
    wwdFBM.wrapT = MirroredRepeatWrapping
    wwdFBM.needsUpdate = true
    super('CompositeEffect', glsl(fragment), {
      blendFunction: BlendFunction.NORMAL,
      uniforms: new Map([
        ['resolution', new Uniform(new Vector2(window.innerWidth, window.innerHeight))],
        ['boost', new Uniform(1)],
        ['dustOpacity', new Uniform(1)],
        ['time', new Uniform(0)],
        ['overlayImg', new Uniform(overlay)],
        ['lensPos', new Uniform(new Vector2())],
        ['lensFlareOpacity', new Uniform(1)],
        ['halo', new Uniform(blurTarget.texture)],
        ['haloAmount', new Uniform(1)],
        ['vignette', new Uniform(0)],
        ['logo', new Uniform(logo)],
        ['logoOpacity', new Uniform(1)],
        ['wwdFBM', new Uniform(wwdFBM)],
        ['specular', new Uniform(5)],
        ['lightPosition', new Uniform(new Vector3(1, 1, 1))],
        ['normalAmount', new Uniform(1000)],
      ]),
    })
    if (haloRTT === undefined) {
      halo = new HaloMaterial()
      haloRTT = new RTT(halo)
      blur = new KawaseBlurPass({ height: 480 })
      const parentFolder = debugFolder('Post')
      const blurFolder = debugFolder('Blur', false, parentFolder)
      debugInput(blurFolder, blur, 'scale', {
        min: 0,
        max: 1,
        step: 0.01,
      })
      debugInput(blurFolder, blur, 'scale', {
        min: 0,
        max: 1,
        step: 0.01,
      })
    }
  }

  update(renderer: WebGLRenderer, inputBuffer: WebGLRenderTarget, deltaTime: number) {
    const width = window.innerWidth
    const height = window.innerHeight
    if (width !== haloTarget.width || height !== haloTarget.height) {
      haloTarget.setSize(width, height)
      blurTarget.setSize(width, height)
      blur.setSize(width, height)
    }

    // Halo
    // console.log(halo.mat)
    haloRTT.material.map = inputBuffer.texture
    haloRTT.render(renderer, haloTarget)
    //
    blur.render(renderer, haloTarget, blurTarget)

    this['uniforms'].get('resolution').value.set(width, height)
    this['uniforms'].get('time').value += deltaTime
    const boosted = clamp(-0.5, 0.5, perlin.noise(this['uniforms'].get('time').value, 1, 1))
    this.boost = map(-0.5, 0.5, config.range.x, config.range.y, boosted)

    const wwdVisible = !(app.currentPage === 'what-we-do' && app.headerVisible)
    this.logoOpacity = wwdVisible ? 0 : 1
    // console.log(this.lensX, this.lensY)
  }

  get lightPosition(): Vector3 {
    return this['uniforms'].get('lightPosition').value
  }

  set lightPosition(value: Vector3) {
    this['uniforms'].get('lightPosition').value = value
  }

  get normalAmount(): number {
    return this['uniforms'].get('normalAmount').value
  }

  set normalAmount(value: number) {
    this['uniforms'].get('normalAmount').value = value
  }

  get warp(): number {
    return halo.warp
  }

  set warp(value: number) {
    halo.warp = value
  }

  get vignette(): number {
    return this['uniforms'].get('vignette').value
  }

  set vignette(value: number) {
    this['uniforms'].get('vignette').value = value
  }

  get lensFlare(): number {
    return this['uniforms'].get('lensFlareOpacity').value
  }

  set lensFlare(value: number) {
    this['uniforms'].get('lensFlareOpacity').value = value
  }

  get lensX(): number {
    return this['uniforms'].get('lensPos').value.x
  }

  set lensX(value: number) {
    this['uniforms'].get('lensPos').value.x = value
  }

  get lensY(): number {
    return this['uniforms'].get('lensPos').value.y
  }

  set lensY(value: number) {
    this['uniforms'].get('lensPos').value.y = value
  }

  get logoOpacity(): number {
    return this['uniforms'].get('logoOpacity').value
  }

  set logoOpacity(value: number) {
    this['uniforms'].get('logoOpacity').value = value
  }

  get specular(): number {
    return this['uniforms'].get('specular').value
  }

  set specular(value: number) {
    this['uniforms'].get('specular').value = value
  }

  get boost(): number {
    return this['uniforms'].get('boost').value
  }

  set boost(value: number) {
    this['uniforms'].get('boost').value = value
  }

  get dustOpacity(): number {
    return this['uniforms'].get('dustOpacity').value
  }

  set dustOpacity(value: number) {
    this['uniforms'].get('dustOpacity').value = value
  }

  get haloAmount(): number {
    return this['uniforms'].get('haloAmount').value
  }

  set haloAmount(value: number) {
    this['uniforms'].get('haloAmount').value = value
  }

  get minRange() {
    return config.range.x
  }

  set minRange(value: number) {
    config.range.x = value
  }

  get maxRange() {
    return config.range.y
  }

  set maxRange(value: number) {
    config.range.y = value
  }
}
