// Libs
import React, { memo, useEffect } from 'react'
import { Stats } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { Box } from '@chakra-ui/react'
import { WebGLRenderer } from 'three'
import { Quality, Settings } from 'hooks/useAssetLoader'
// Utils
import { debugOptions, disposeDebug } from './utils/debug'
// Components
import DebugPerformance from './components/DebugPerformance'
import ThreeScene from './ThreeScene'
import { Animations, app, Events, dispatcher } from './global'
import { useAppContext } from 'context/AppContext'
import { IS_DEV } from '../constants'

function WildlifeApp() {
  const initDebug = () => {
    const options = []
    for (const i in Animations) {
      options.push({
        text: i,
        value: i,
      })
    }
    debugOptions(undefined, 'Animation', options, (value: any) => {
      console.log('item', value)
      dispatcher.dispatchEvent({
        type: Events.ANIMATION,
        value: value,
      })
    })
  }

  useEffect(() => {
    if (IS_DEV) initDebug()
    return () => {
      disposeDebug()
    }
  }, [])

  // Listen for URL updates
  const context = useAppContext()
  useEffect(() => {
    const location = window.location.pathname.substring(1)
    const primary = location.split('/')[0]
    if (primary !== app.currentPage) {
      app.currentPage = primary
      dispatcher.dispatchEvent({
        type: Events.URL_UPDATE,
        value: app.currentPage,
      })
    }
  }, [context])

  return (
    <Box pos="fixed" top={0}>
      {IS_DEV && <Stats className="stats" />}
      <Canvas
        camera={{
          filmGauge: 35,
          filmOffset: 0,
          focus: 10,
          fov: 90,
          near: 0.1,
          far: 100,
        }}
        dpr={Settings.quality !== Quality.LOW ? [1, 2] : 1}
        gl={(canvas) =>
          new WebGLRenderer({
            alpha: false,
            antialias: false,
            canvas: canvas,
            stencil: false,
          })
        }
        onCreated={(state) => {
          const width = window.innerWidth
          const height = window.innerHeight
          state.gl.setSize(width, height)
        }}
      >
        {IS_DEV && <DebugPerformance />}
        <fog attach="fog" color={0x000000} near={10} far={50} />
        <ThreeScene />
      </Canvas>
    </Box>
  )
}

export default memo(WildlifeApp)
