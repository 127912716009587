import { Mesh, MeshBasicMaterial, Texture, Vector2 } from 'three'
import { getAsset } from 'hooks/useAssetLoader'
import { planeGeometry } from '../../global'

export const ArrowSize = new Vector2(160, 149)
const leftMaterial = new MeshBasicMaterial({ alphaTest: 0.1, transparent: true })
const rightMaterial = new MeshBasicMaterial({ alphaTest: 0.1, transparent: true })
let leftArrow: Texture | null = null

export default class Arrow extends Mesh {
  constructor(direction: number) {
    super(planeGeometry, direction > 0 ? rightMaterial : leftMaterial)
    this.scale.set(ArrowSize.x, ArrowSize.y, 1)

    const needsUpdate = rightMaterial.map === null
    if (needsUpdate) {
      const arrowTexture = getAsset('arrow', 'homePage').data as Texture
      const repeat = 1.5

      // Right
      arrowTexture.offset.set(-0.25, -0.25)
      arrowTexture.repeat.set(repeat, repeat)
      rightMaterial.map = arrowTexture
      rightMaterial.needsUpdate = true

      // Left
      leftArrow = arrowTexture.clone()
      leftArrow.name = 'leftArrow'
      leftArrow.offset.x = 1.25
      leftArrow.repeat.x = -1.5
      leftArrow.needsUpdate = true
      leftMaterial.map = leftArrow
      leftMaterial.needsUpdate = true
    }
  }
}
