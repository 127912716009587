import { chakra } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { motion, useAnimation, useMotionTemplate, useSpring } from 'framer-motion'

import { MotionIcon } from 'components/Motion'

import useSafariCheck from 'hooks/useSafariCheck'

import colors from 'constants/colors'

interface ArtworkLoaderProps {
  progress: number
  isLoadingComplete: boolean
  onAnimationComplete: () => void
  className?: string
}

const SCALE_IN_DURATION = 1
const CLIP_SLANT = 24

const ArtworkLoader = ({ className, progress, isLoadingComplete, onAnimationComplete }: ArtworkLoaderProps) => {
  const refOrbitOne = useRef<SVGPathElement>()
  const refOrbitTwo = useRef<SVGPathElement>()

  const [orbitOneLength, setOrbitOneLength] = useState(0)
  const [orbitTwoLength, setOrbitTwoLength] = useState(0)
  const [isAnimatedIn, setIsAnimatedIn] = useState(false)
  const [isAnimatingOut, setIsAnimatingOut] = useState(false)

  const animationIcon = useAnimation()
  const animationOrbits = useAnimation()
  const animationOrbitOne = useAnimation()
  const animationOrbitTwo = useAnimation()
  const animationMaskOrbitOne = useAnimation()
  const animationMaskOrbitTwo = useAnimation()

  const clipBottom = useSpring(0, { stiffness: 200, damping: 100 })
  const clipTop = useSpring(-CLIP_SLANT, { stiffness: 200, damping: 100 })
  const clipPath = useMotionTemplate`polygon(0 0, ${clipTop}% 0, ${clipBottom}% 100%, 0% 100%)`

  const isSafari = useSafariCheck()

  useEffect(() => {
    if (refOrbitOne?.current) {
      setOrbitOneLength(Math.ceil(refOrbitOne.current.getTotalLength()))
    }
    if (refOrbitTwo?.current) {
      setOrbitTwoLength(Math.ceil(refOrbitTwo.current.getTotalLength()))
    }
  }, [])

  useEffect(() => {
    if (isAnimatedIn) {
      clipTop.set(progress)
      clipBottom.set(progress + CLIP_SLANT)

      clipTop.onChange((current) => {
        if (current === 100 && isLoadingComplete) {
          setIsAnimatingOut(true)
        }
      })
    }
  }, [progress, clipTop, clipBottom, isAnimatedIn, isLoadingComplete])

  useEffect(() => {
    if (!orbitOneLength || !orbitTwoLength) {
      return
    }

    const sequence = async () => {
      const offsetDirection = isSafari ? 1 : -1
      const rotateDirection = isSafari ? -1 : 1

      animationMaskOrbitOne.set({ strokeDashoffset: orbitOneLength * offsetDirection })
      animationMaskOrbitTwo.set({ strokeDashoffset: orbitTwoLength * offsetDirection })

      await animationIcon.start({
        opacity: [0, 1, 0.2, 1, 0.2, 1],
        transition: {
          ease: 'linear',
          duration: 0.3,
        },
      })

      animationOrbits.start({
        rotate: [0, 360 * rotateDirection],
        transition: {
          ease: 'linear',
          duration: 60,
          repeat: Infinity,
        },
      })

      animationOrbitOne.start({
        scale: [0, 1],
        transition: {
          scale: {
            ease: [0.25, 0, 0, 1],
            duration: SCALE_IN_DURATION,
          },
        },
      })

      animationOrbitTwo.start({
        scale: [0, 1],
        transition: {
          delay: 0.2,
          scale: {
            ease: [0.25, 0, 0, 1],
            duration: SCALE_IN_DURATION,
          },
        },
      })

      animationMaskOrbitOne.start({
        strokeDashoffset: 0,
        transition: {
          delay: SCALE_IN_DURATION - 0.4,
          ease: [0.25, 0, 0, 1],
          duration: 1,
        },
      })

      await animationMaskOrbitTwo.start({
        strokeDashoffset: 0,
        transition: {
          delay: SCALE_IN_DURATION - 0.2,
          ease: [0.25, 0, 0, 1],
          duration: 1,
        },
      })

      setIsAnimatedIn(true)
    }

    sequence()
  }, [
    animationIcon,
    animationOrbits,
    animationOrbitOne,
    animationOrbitTwo,
    animationMaskOrbitOne,
    animationMaskOrbitTwo,
    orbitOneLength,
    orbitTwoLength,
    isSafari,
  ])

  useEffect(() => {
    if (!isAnimatingOut) {
      return
    }

    const sequence = async () => {
      animationOrbitOne.start({
        scale: 0,
        transition: {
          scale: {
            ease: [1, 0, 1, 1],
            duration: 0.3,
          },
        },
      })

      animationOrbitTwo.start({
        scale: 0,
        transition: {
          scale: {
            ease: [1, 0, 1, 1],
            duration: 0.3,
            delay: 0.1,
          },
        },
      })

      await animationIcon.start({
        scale: 0,
        transition: {
          ease: [0.25, 0, 0, 1],
          duration: 0.3,
          delay: 0.3,
        },
      })

      onAnimationComplete()
    }

    sequence()
  }, [isAnimatingOut, animationIcon, animationOrbitOne, animationOrbitTwo])

  return (
    <MotionIcon viewBox="0 0 478 478" className={className} animate={animationIcon}>
      <g fill="none" fillRule="evenodd">
        <motion.g transform-origin="239 239" animate={animationOrbits}>
          <circle width="478" height="478" cx="239" cy="239" r="239" />

          {/* Orbit one */}
          <motion.g animate={animationOrbitOne} style={{ transform: 'scale(0)' }}>
            <g transform="rotate(120)" style={{ transformOrigin: 'center' }}>
              <mask id="artworkLoadermaskOrbitOne">
                <motion.path
                  d="M239 337c54.124 0 98-43.876 98-98s-43.876-98-98-98-98 43.876-98 98 43.876 98 98 98Z"
                  stroke="#FFF"
                  strokeWidth={3}
                  style={{ strokeDasharray: orbitOneLength }}
                  strokeDashoffset={-orbitOneLength}
                  animate={animationMaskOrbitOne}
                  key={orbitOneLength}
                />
              </mask>

              <path
                mask="url(#artworkLoadermaskOrbitOne)"
                d="M239 337c54.124 0 98-43.876 98-98s-43.876-98-98-98-98 43.876-98 98 43.876 98 98 98Z"
                strokeOpacity=".3"
                stroke="#FFF"
                strokeDasharray="2"
                ref={refOrbitOne}
              />

              <circle stroke={colors.blue_14} strokeWidth="7" fill="#59575F" cx="236.5" cy="337.5" r="7.5" />
            </g>
          </motion.g>

          {/* Orbit two */}
          <motion.g animate={animationOrbitTwo} style={{ transform: 'scale(0)' }}>
            <g transform="rotate(-90)" style={{ transformOrigin: 'center' }}>
              <mask id="artworkLoadermaskOrbitTwo">
                <motion.path
                  d="M239 465c124.816 0 226-101.184 226-226S363.816 13 239 13 13 114.184 13 239s101.184 226 226 226Z"
                  stroke="#FFF"
                  strokeWidth={3}
                  style={{ strokeDasharray: orbitTwoLength }}
                  strokeDashoffset={-orbitTwoLength}
                  animate={animationMaskOrbitTwo}
                  key={orbitTwoLength}
                />
              </mask>

              <path
                mask="url(#artworkLoadermaskOrbitTwo)"
                d="M239 465c124.816 0 226-101.184 226-226S363.816 13 239 13 13 114.184 13 239s101.184 226 226 226Z"
                strokeOpacity=".3"
                stroke="#FFF"
                strokeDasharray="2"
                ref={refOrbitTwo}
              />

              <circle stroke={colors.blue_14} strokeWidth="7" fill="#59575F" cx="236.5" cy="465.5" r="7.5" />
            </g>
          </motion.g>
        </motion.g>

        {/* Outline logo mark */}
        <path
          d="m247.278 233.954-3.668 9.172-3.634-11.705-.496-1.595-.465 1.604-8.863 30.57h-4.366L210 217h7.037l9.415 29.578.11.348H228.726l.105-.359L237.442 217h4.722l5.114 16.954Zm.854 23.526L262.351 217H269l-15.27 45h-4.094l-1.504-4.52Z"
          strokeOpacity=".3"
          stroke="#FFF"
          opacity=".4"
        />

        {/* Fill logo mark */}
        <motion.path
          d="m247.278 233.954-3.668 9.172-3.634-11.705-.496-1.595-.465 1.604-8.863 30.57h-4.366L210 217h7.037l9.415 29.578.11.348H228.726l.105-.359L237.442 217h4.722l5.114 16.954Zm.854 23.526L262.351 217H269l-15.27 45h-4.094l-1.504-4.52Z"
          fill="#00C0F0"
          style={{ clipPath }}
        />
      </g>
    </MotionIcon>
  )
}

export default chakra(ArtworkLoader)
