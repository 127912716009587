import { rem } from 'polished'
import { HTMLInputTypeAttribute } from 'react'
import { chakra, Input } from '@chakra-ui/react'
import { UseFormRegister, FieldValues } from 'react-hook-form'

interface InputOutlineProps {
  required: boolean
  register: UseFormRegister<FieldValues>
  type: HTMLInputTypeAttribute
  placeholder: string
  className?: string
}

const InputOutline = ({ className, register, required, type, placeholder }: InputOutlineProps) => {
  return (
    <Input
      className={className}
      h="unset"
      minH="unset"
      py={{ base: rem(11), xl: rem(32) }}
      px={{ base: rem(20), xl: rem(42) }}
      borderWidth={{ base: rem(0.7), xl: rem(2) }}
      borderRadius={rem(60)}
      fontFamily="body"
      fontSize={{ base: rem(20), xl: rem(36) }}
      letterSpacing={{ base: rem(-0.44), xl: rem(-1.25) }}
      lineHeight={{ base: rem(17), xl: rem(50) }}
      _placeholder={{
        marginLeft: rem(6),
        color: 'currentcolor',
        opacity: 0.7,
      }}
      _hover={{
        borderColor: 'currentcolor',
      }}
      _focus={{
        borderColor: 'currentcolor',
      }}
      placeholder={placeholder}
      type={type}
      {...register(type, { required })}
    />
  )
}

export default chakra(InputOutline)
