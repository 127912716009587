import { useState } from 'react'
import { rem, rgba } from 'polished'
import { Box, Center } from '@chakra-ui/react'

import NavigationList from 'components/navigation/NavigationList'
import Button from 'components/buttons/Button'
import Logo from 'components/Logo'

import useScrollTicker from 'hooks/useScrollTicker'
import { useAppContext } from 'context/AppContext'

import { ContentfulGraphRoute } from 'contentful/types'
import { pct } from 'utils/designHelper'
import colors from 'constants/colors'

interface NavigationDesktopProps {
  links: ContentfulGraphRoute[]
  isEnabled: boolean
}

const NavigationDesktop = ({ links, isEnabled }: NavigationDesktopProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isMouseActive, setisMouseActive] = useState(false)
  const [isListVisible, setIsListVisible] = useState(true)

  const context = useAppContext()

  function handleScrollTick() {
    if (!isCollapsed && window.scrollY > 0) {
      setIsCollapsed(true)
      setisMouseActive(true)
      setIsListVisible(false)
    }

    if (window.scrollY === 0) {
      setIsCollapsed(false)
      setisMouseActive(false)
      setIsListVisible(true)
    }
  }

  function handleExpandList() {
    setIsListVisible(true)
  }

  function handleCollapseList() {
    setIsListVisible(false)
  }

  useScrollTicker(handleScrollTick, true, [isCollapsed])

  return (
    <Center
      pos="fixed"
      top={0}
      pt={{ base: rem(26), xl: rem(62) }}
      zIndex="navigation"
      w="100%"
      pointerEvents="none"
      _before={{
        content: '""',
        pos: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        w: '100%',
        h: { base: rem(100), xl: rem(200) },
        background: `linear-gradient(${colors.blue_4} 20%, ${rgba(colors.blue_4, 0)})`,
        opacity: context?.isNavigationDarkTheme ? 0.6 : 0,
        transition: 'opacity 0.2s linear',
        pointerEvents: 'none',
      }}
    >
      <Box
        as="nav"
        pos="relative"
        display="flex"
        w={{ base: '100%', xl: pct(1308, 1440) }}
        px={{ base: rem(30), xl: 0 }}
      >
        <Button
          pos="relative"
          zIndex={2}
          w={{ base: rem(58), xl: rem(79) }}
          ml={{ xl: rem(15) }}
          href="/"
          pointerEvents={isEnabled ? 'auto' : 'none'}
          variants={variantsLogo}
          animate={isCollapsed ? 'collapsed' : 'initial'}
        >
          <Logo w="100%" h="100%" color="blue_1" />
        </Button>

        <NavigationList
          links={links}
          isEnabled={isEnabled}
          isMouseActive={isMouseActive}
          isCollapsed={isCollapsed}
          isVisible={isListVisible}
          onExpand={handleExpandList}
          onCollapse={handleCollapseList}
        />
      </Box>
    </Center>
  )
}

const variantsLogo = {
  initial: {
    // x: 0,
    y: 0,
    transition: {
      ease: [0.25, 0, 0, 1],
      duration: 0.3,
    },
  },
  collapsed: {
    // x: rem(-30),
    y: rem(-30),
    transition: {
      ease: [0.25, 0, 0, 1],
      duration: 0.3,
    },
  },
}

export default NavigationDesktop
