import { useState, useEffect } from 'react'

interface TouchInterface {
  x: number
  y: number
}

interface TouchPositionProps {
  isEnabled?: boolean
}

const useTouchPosition = ({ isEnabled = false }: TouchPositionProps) => {
  const [mouse, setTouch] = useState<TouchInterface | null>(null)

  useEffect(() => {
    function handleTouchMove(e: TouchEvent) {
      const touch = e.touches[0]
      setTouch({
        x: touch.pageX,
        y: touch.pageY,
      })
    }

    if (isEnabled) {
      window.addEventListener('touchstart', handleTouchMove)
      window.addEventListener('touchmove', handleTouchMove)
    }

    return () => {
      window.removeEventListener('touchstart', handleTouchMove)
      window.removeEventListener('touchmove', handleTouchMove)
    }
  }, [isEnabled])

  return isEnabled ? mouse : null
}

export default useTouchPosition
