import { rem } from 'polished'
import { As, chakra, Container, forwardRef } from '@chakra-ui/react'

import { ARTBOARD_WIDTH_XL } from 'constants/design'

interface ContainerGridProps {
  children: React.ReactNode
  className?: string
  tag?: As
}

const ContainerGrid = forwardRef(({ children, className, tag, ...props }: ContainerGridProps, ref) => {
  return (
    <Container
      as={tag}
      className={className}
      maxW={{ xl: rem(ARTBOARD_WIDTH_XL) }}
      px={{ base: rem(30), xl: rem(120) }}
      {...props}
      ref={ref}
    >
      {children}
    </Container>
  )
})

export default chakra(ContainerGrid)
