import { chakra } from '@chakra-ui/react'

import NavigationDesktop from 'components/navigation/NavigationDesktop'
import NavigationMobile from 'components/navigation/NavigationMobile'

import useMediaQuery from 'hooks/useMediaQuery'

import { ContentfulGraphRoute, ContentfulGraphSocialLink } from 'contentful/types'
import breakpoints from 'constants/breakpoints'

interface NavigationProps {
  links: ContentfulGraphRoute[]
  socials: ContentfulGraphSocialLink[]
  isEnabled: boolean
}

const Navigation = ({ links, socials, isEnabled }: NavigationProps) => {
  const isNarrowScreen = useMediaQuery(breakpoints.lg)

  return isNarrowScreen ? (
    <NavigationMobile links={links} socials={socials} isEnabled={isEnabled} />
  ) : (
    <NavigationDesktop links={links} isEnabled={isEnabled} />
  )
}

export default chakra(Navigation)
