export function clamp(min: number, max: number, value: number) {
  return Math.min(max, Math.max(min, value))
}

export function mix(min: number, max: number, value: number) {
  return min * (1 - value) + max * value
}

export function normalize(min: number, max: number, value: number) {
  return (value - min) / (max - min)
}

export function map(min1: number, max1: number, min2: number, max2: number, value: number) {
  return mix(min2, max2, normalize(min1, max1, value))
}

export function precisionComplete(current: number, destination: number, precision: number): boolean {
  return current < destination + precision && current > destination - precision
}

export function cosRange(value: number, range: number, min: number): number {
  return (1 + Math.cos(value)) * 0.5 * range + min
}

export function distance(x: number, y: number): number {
  const d = x - y
  return Math.sqrt(d * d)
}

// Bezier math

export function isLinear(x0: number, y0: number, x1: number, y1: number): boolean {
  return x0 === y0 && x1 === y1
}

export function curveAt(percent: number, x0: number, y0: number, x1: number, y1: number): number {
  if (percent <= 0) return 0
  if (percent >= 1) return 1
  if (isLinear(x0, y0, x1, y1)) return percent // linear

  const x0a = 0 // initial x
  const y0a = 0 // initial y
  const x1a = x0 // 1st influence x
  const y1a = y0 // 1st influence y
  const x2a = x1 // 2nd influence x
  const y2a = y1 // 2nd influence y
  const x3a = 1 // final x
  const y3a = 1 // final y

  const A = x3a - 3.0 * x2a + 3.0 * x1a - x0a
  const B = 3.0 * x2a - 6.0 * x1a + 3.0 * x0a
  const C = 3.0 * x1a - 3.0 * x0a
  const D = x0a

  const E = y3a - 3.0 * y2a + 3.0 * y1a - y0a
  const F = 3.0 * y2a - 6.0 * y1a + 3.0 * y0a
  const G = 3.0 * y1a - 3.0 * y0a
  const H = y0a

  let current = percent
  let i, currentx, currentslope
  for (i = 0; i < 5; i++) {
    currentx = xFromT(current, A, B, C, D)
    currentslope = slopeFromT(current, A, B, C)
    if (currentslope === Infinity) currentslope = percent
    current -= (currentx - percent) * currentslope
    current = Math.min(Math.max(current, 0.0), 1.0)
  }

  return yFromT(current, E, F, G, H)
}

export function slopeFromT(t: number, A: number, B: number, C: number): number {
  return 1.0 / (3.0 * A * t * t + 2.0 * B * t + C)
}

export function xFromT(t: number, A: number, B: number, C: number, D: number): number {
  return A * (t * t * t) + B * (t * t) + C * t + D
}

export function yFromT(t: number, E: number, F: number, G: number, H: number): number {
  const tt = t * t
  return E * (tt * t) + F * tt + G * t + H
}
