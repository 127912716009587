import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import app from './reducers/app'

const reducers = combineReducers({
  app: app.reducer,
})

export const store = configureStore({
  reducer: reducers,
  devTools: true,
})
