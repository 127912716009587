import { useThree } from '@react-three/fiber'
import { useEffect } from 'react'
import { Object3D } from 'three'
import { dispatcher, Events } from '../global'

export default function CursorHandler() {
  const { gl } = useThree()

  const onRollOver = (item: Object3D) => {
    if (item.name.length > 0) {
      gl.domElement.style.cursor = 'pointer'
    } else {
      gl.domElement.style.cursor = 'grab'
    }
  }

  const onRollOut = () => {
    gl.domElement.style.cursor = 'initial'
  }

  const onCursor = (evt: any) => {
    if (evt.value !== undefined) {
      onRollOver(evt.value)
    } else {
      onRollOut()
    }
  }

  useEffect(() => {
    dispatcher.addEventListener(Events.CURSOR, onCursor)
    return () => {
      dispatcher.removeEventListener(Events.CURSOR, onCursor)
    }
  }, [])
  return null
}
