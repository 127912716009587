import { extendTheme, theme, ThemeConfig } from '@chakra-ui/react'

import Button from './components/button'

import { breakpointsEm as breakpoints } from '../constants/breakpoints'
import colors from '../constants/colors'

import borders from './borders'
import radii from './radius'
import shadows from './shadows'
import transition from './transition'
import typography from './typography'
import zIndices from './z-index'
import blur from './blur'

const direction = 'ltr'

const config: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: 'light',
  cssVarPrefix: 'chakra',
}

export const styles = {
  global: {
    body: {
      fontFamily: null,
      lineHeight: null,
      color: null,
    },
  },
}

export default extendTheme({
  ...theme,
  borders,
  breakpoints,
  blur,
  config,
  colors,
  components: { Button },
  direction,
  radii,
  shadows,
  // styles
  ...typography,
  transition,
  zIndices,
})
