import React, { createContext, useState, useContext, ReactNode } from 'react'

import { Animations, dispatcher, Events } from '../three/global'

interface ThreeContextProviderProps {
  children: ReactNode
}

type ThreeStateType = {
  animation: keyof typeof Animations
  loadState: keyof typeof Events
  loadProgress: number
  isSceneVisible: boolean
  isSceneDisabled: boolean
  isSceneEnteringIn: boolean
} | null
type ThreeDispatchType = React.Dispatch<React.SetStateAction<ThreeStateType>> | null

const ThreeContext = createContext<ThreeStateType>(null)
const ThreeDispatchContext = createContext<ThreeDispatchType>(null)

const DEFAULT_STATE = {
  animation: null,
  loadState: null,
  loadProgress: 0,
  isSceneVisible: true,
  isSceneDisabled: false,
  isSceneEnteringIn: false,
}

const ThreeContextProvider = ({ children }: ThreeContextProviderProps) => {
  const [state, setState] = useState<ThreeStateType>(DEFAULT_STATE)

  return (
    <ThreeContext.Provider value={state}>
      <ThreeDispatchContext.Provider value={setState}>{children}</ThreeDispatchContext.Provider>
    </ThreeContext.Provider>
  )
}

export function useThreeContext() {
  const state = useContext(ThreeContext)

  if (typeof state === 'undefined') {
    throw new Error('useThreeContext must be used within a ThreeContextProvider')
  }

  return state
}

export function useThreeDispatchContext() {
  const setState = useContext(ThreeDispatchContext)

  if (setState === null) {
    return
  }

  if (typeof setState === 'undefined') {
    throw new Error('useThreeDispatchContext must be used within a ThreeContextProvider')
  }

  return {
    changeAnimation: (animation: ThreeStateType['animation']) => {
      setState((prevState) => ({ ...prevState, animation }))
    },
    changeLoadState: (loadState: ThreeStateType['loadState']) => {
      setState((prevState) => ({ ...prevState, loadState }))
    },
    changeLoadProgress: (loadProgress: ThreeStateType['loadProgress']) => {
      setState((prevState) => ({ ...prevState, loadProgress }))
    },
    changeIsSceneVisible: (isSceneVisible: ThreeStateType['isSceneVisible']) => {
      setState((prevState) => ({ ...prevState, isSceneVisible }))
    },
    changeIsSceneDisabled: (isSceneDisabled: ThreeStateType['isSceneDisabled']) => {
      setState((prevState) => ({ ...prevState, isSceneDisabled }))
    },
    changeIsSceneEnteringIn: (isSceneEnteringIn: ThreeStateType['isSceneEnteringIn']) => {
      if (isSceneEnteringIn) {
        dispatcher.dispatchEvent({
          type: Events.ANIMATION,
          value: Animations.Intro,
        })
      }
      setState((prevState) => ({ ...prevState, isSceneEnteringIn }))
    },
  }
}

export default ThreeContextProvider
