export const ARTBOARD_WIDTH_SM = 375
export const ARTBOARD_WIDTH_XL = 1440
export const GRID_WIDTH_SM = 315
export const GRID_WIDTH_XL = 1200

export const GRID_TYPES = {
  square: 'square',
  varying: 'varying',
} as const

export interface GridTypeInterface {
  uid: number
  type: keyof typeof GRID_TYPES
  label: string
}
export const TWO_COLUMN_LAYOUTS = {
  AUTO: 'Auto',
  IMAGE_RIGHT: 'Image Right',
  IMAGE_LEFT: 'Image Left',
  TEXT_ONLY_LEFT: 'Text Only Left',
  TEXT_ONLY_RIGHT: 'Text Only Right',
  TWO_COLUMN_TEXT: 'Two Column Text',
}
export const PROJECT_FLEX_MODULES = {
  METRICS: 'ProjectMetricsModule',
  HEADLINE: 'ProjectHeadlineModule',
  VIMEO: 'VimeoPlayer',
  CARDS_GALLERY: 'ProjectStackedImageCardsModule',
  GRID_GALLERY: 'ProjectImageGridModule',
  COPY_COLUMNS: 'ProjectCopyColumnsModule',
  FULL_WIDTH_IMAGE: 'ProjectFullWidthImageModule',
  QUOTE: 'ProjectQuoteModule',
  COPY_IMAGE_COLUMNS: 'ProjectCopyImageColumns',
  VIDEO_PLAYER: 'VideoPlayer',
}

export const SOCIAL_KEYS = {
  instagram: 'instagram',
  linkedin: 'linkedin',
  facebook: 'facebook',
  twitter: 'twitter',
  vimeo: 'vimeo',
} as const

export const DATA_COMPONENTS = {
  projectVideoCopyCarousel: 'project-video-copy-carousel',
  projectRelated: 'project-related',
}
