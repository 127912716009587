import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
  name: 'app',
  initialState: {
    loading: false,
    loaded: false,
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.value
    },
    setLoaded: (state, { payload }) => {
      state.loaded = payload.value
    },
  },
})

export default appSlice
