import { curveAt, mix, normalize } from 'utils/math'

const linear = [0.25, 0.25, 0.75, 0.75]

export function Tween(obj: object, duration: number, opts: any) {
  this.object = obj
  this.duration = duration
  this.start = {}
  this.target = {}
  this.params = []
  this.complete = false
  this.elapsedTime = 0
  this.ease = opts.ease !== undefined ? opts.ease : linear
  this.onUpdate = opts.onUpdate
  this.onComplete = opts.onComplete

  // Add paramaters
  for (const i in opts) {
    const name = i as string
    if (obj[i] !== undefined) {
      const skip = name === 'delay' || name === 'ease' || name === 'onUpdate' || name === 'onComplete'
      if (!skip) {
        this.params.push(i)
        this.start[i] = obj[i]
        this.target[i] = opts[i]
      }
    }
  }

  // Set times
  const delay = opts.delay !== undefined ? opts.delay : 0
  this.startTime = delay
  this.endTime = duration + this.startTime

  this.update = (delta: number) => {
    const now = this.elapsedTime
    let percent = 1

    if (now > this.endTime) {
      this.complete = true
    } else if (now < this.startTime) {
      percent = 0
    } else {
      percent = normalize(this.startTime, this.endTime, now)
    }

    const bezier = curveAt(percent, this.ease[0], this.ease[1], this.ease[2], this.ease[3])
    this.updateObjects(bezier)

    if (this.onUpdate !== undefined) {
      this.onUpdate(bezier)
    }

    if (this.complete && this.onComplete !== undefined) {
      this.onComplete()
    }

    this.elapsedTime += delta
  }

  this.updateObjects = (percent: number) => {
    for (const i in this.params) {
      const name = this.params[i]
      const start = this.start[name]
      const target = this.target[name]
      const value = mix(start, target, percent)
      this.object[name] = value
    }
  }
}

export function TweenController() {
  this.tweens = []

  this.to = (obj: object, duration: number, opts: any) => {
    const total = this.tweens.length
    for (let i = 0; i < total; i++) {
      const tween = this.tweens[i]
      // Remove previous tween
      if (tween.object === obj) {
        this.tweens.splice(i, 1)
        break
      }
    }
    // New tween
    this.tweens.push(new Tween(obj, duration, opts))
  }

  this.update = (delta: number) => {
    let total = this.tweens.length
    for (let i = 0; i < total; ++i) {
      this.tweens[i].update(delta)
      if (this.tweens[i].complete) {
        this.tweens.splice(i, 1)
        --i
        --total
      }
    }
  }
}
