import { chakra } from '@chakra-ui/react'

import { MotionBox } from './Motion'

interface TransitionWipeProps {
  onTranstionComplete: () => void
  className?: string
}

const DURATION = 0.6

const TransitionWipe = ({ className, onTranstionComplete }: TransitionWipeProps) => {
  return (
    <MotionBox
      className={className}
      pos="fixed"
      top={0}
      left="-100%"
      zIndex="transitionWipe"
      w="100%"
      h="100%"
      bgColor="blue_14"
      transformOrigin="left"
      variants={variantsContainer}
      initial="initial"
      animate="animate"
      onAnimationComplete={onTranstionComplete}
      exit="exit"
    >
      <MotionBox
        pos="absolute"
        top={0}
        right={0}
        w="10%"
        h="100%"
        bgColor="blue_1"
        transformOrigin="right"
        variants={variantsBar}
      />
    </MotionBox>
  )
}

const variantsContainer = {
  initial: {
    x: 0,
  },
  animate: {
    x: '100%',
    transition: {
      duration: DURATION,
    },
  },
  exit: {
    opacity: 0,
  },
}

const variantsBar = {
  initial: {
    scaleX: 1,
  },
  animate: {
    scaleX: 0,
    transition: {
      duration: DURATION,
    },
  },
}

export default chakra(TransitionWipe)
