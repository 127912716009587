import { motion } from 'framer-motion'

import {
  AspectRatio,
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  Link,
  Modal,
  ModalHeader,
  SimpleGrid,
  Text,
  VStack,
  List,
  ListItem,
} from '@chakra-ui/react'

export const MotionAspectRatio = motion(AspectRatio)
export const MotionButton = motion(Button)
export const MotionBox = motion(Box)
export const MotionCenter = motion(Center)
export const MotionCheckbox = motion(Checkbox)
export const MotionContainer = motion(Container)
export const MotionFlex = motion(Flex)
export const MotionHeading = motion(Heading)
export const MotionHStack = motion(HStack)
export const MotionIcon = motion(Icon)
export const MotionInput = motion(Input)
export const MotionImage = motion(Image)
export const MotionLink = motion(Link)
export const MotionModal = motion(Modal)
export const MotionModalHeader = motion(ModalHeader)
export const MotionSimpleGrid = motion(SimpleGrid)
export const MotionText = motion(Text)
export const MotionVStack = motion(VStack)
export const MotionList = motion(List)
export const MotionListItem = motion(ListItem)
