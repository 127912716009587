import { chakra } from '@chakra-ui/react'

import ArtworkLoader from 'components/artwork/ArtworkLoader'
import { MotionBox } from 'components/Motion'

import { useThreeContext, useThreeDispatchContext } from 'context/ThreeContext'

import { ARTBOARD_WIDTH_SM, ARTBOARD_WIDTH_XL } from 'constants/design'
import { pct } from 'utils/designHelper'

interface PreloaderProps {
  onAnimationComplete: () => void
  className?: string
}

const Preloader = ({ className, onAnimationComplete }: PreloaderProps) => {
  const context = useThreeContext()
  const dispatcher = useThreeDispatchContext()

  function handleAnimationComplete() {
    onAnimationComplete()

    dispatcher.changeIsSceneEnteringIn(true)
  }

  return (
    <MotionBox
      className={className}
      pos="fixed"
      top={0}
      left={0}
      zIndex="preloader"
      display="flex"
      alignItems="center"
      justifyContent="center"
      w="100%"
      h="100%"
      overflow="hidden"
      bgColor="blue_14"
      exit={{
        opacity: 0,
        transition: {
          duration: 0.3,
          ease: 'linear',
        },
      }}
    >
      <ArtworkLoader
        w={{
          base: pct(478, ARTBOARD_WIDTH_SM),
          xl: pct(478, ARTBOARD_WIDTH_XL),
        }}
        h="auto"
        progress={context?.loadProgress || 0}
        isLoadingComplete={context?.loadState === 'LOAD_COMPLETE'}
        onAnimationComplete={handleAnimationComplete}
      />
    </MotionBox>
  )
}

export default chakra(Preloader)
