import { CubeTexture, CubeTextureLoader, FileLoader, LoadingManager, Texture, TextureLoader } from 'three'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useCallback, useEffect, useRef } from 'react'
import { nanoid } from 'nanoid'
import { getGPUTier } from 'detect-gpu'

let detected = false

export enum Quality {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export const Settings = {
  fov: 32,
  fps: 30,
  mobile: false,
  quality: Quality.LOW,
  safari: false,
  tier: 0,
  set: (quality: Quality) => {
    switch (quality) {
      case Quality.HIGH:
        Settings.quality = Quality.HIGH
        Settings.fps = 60
        Settings.tier = 3
        break
      case Quality.MEDIUM:
        Settings.quality = Quality.MEDIUM
        Settings.fps = 30
        Settings.tier = 2
        break
      case Quality.LOW:
        Settings.quality = Quality.LOW
        Settings.fps = 30
        Settings.tier = 1
        break
    }
  },
}

export enum AssetTypes {
  Texture = 'texture',
  Cube = 'cube',
  GLTF = 'gltf',
  JSON = 'json',
}

export type Asset = {
  key: string
  src: string | Array<string>
  type?: AssetTypes
}

type AssetLoaderProps = {
  manifest: Asset[]
  loaderId?: string
  onLoadStart(): void
  onLoadComplete(): void
  onLoadProgress(p: number): void
  isEnabled: boolean
}

type LoaderValues = GLTF | CubeTexture | Texture

type LoadedAsset = {
  data: LoaderValues
  hasLoaded: boolean
}

const __ASSETS = {}

export function getAsset(assetId: string, loaderId: string): LoadedAsset {
  return __ASSETS[loaderId]?.[assetId]
}

export function getLoaded(loaderId: string): boolean {
  return __ASSETS[loaderId]?.hasLoaded
}

function useAssetLoader({
  manifest,
  loaderId = nanoid(),
  onLoadStart,
  onLoadComplete,
  onLoadProgress,
  isEnabled,
}: AssetLoaderProps): string {
  const manager = useRef(new LoadingManager())

  const onStart = useCallback(() => {
    onLoadStart()
  }, [])
  const onProgress = useCallback((url: string, loaded: number, total: number) => {
    onLoadProgress(loaded / total)
  }, [])
  const onLoad = useCallback(() => {
    __ASSETS[loaderId].hasLoaded = true
    onLoadComplete()
  }, [])

  const beginLoad = () => {
    __ASSETS[loaderId] = __ASSETS[loaderId] || {
      hasLoaded: false,
    }

    // Don't reload assets
    if (__ASSETS[loaderId].hasLoaded === true) {
      onLoad()
      return
    }

    manifest.forEach(async ({ key, src, type }) => {
      const hasLoaded = __ASSETS[loaderId][key]?.hasLoaded || false
      __ASSETS[loaderId][key] = {
        hasLoaded,
      }
      if (!hasLoaded) {
        let loader
        switch (type) {
          case AssetTypes.Texture:
            loader = new TextureLoader(manager.current)
            break
          case AssetTypes.Cube:
            loader = new CubeTextureLoader(manager.current)
            break
          case AssetTypes.GLTF:
            loader = new GLTFLoader(manager.current)
            break
          case AssetTypes.JSON:
            loader = new FileLoader(manager.current)
            break
        }

        try {
          __ASSETS[loaderId][key].data = await loader.loadAsync(src)
          if (type === AssetTypes.JSON) {
            __ASSETS[loaderId][key].data = JSON.parse(__ASSETS[loaderId][key].data)
          } else if (type === AssetTypes.Texture) {
            __ASSETS[loaderId][key].data.name = key
          }
          __ASSETS[loaderId][key].hasLoaded = true
        } catch (e) {
          __ASSETS[loaderId][key].error = e
          console.warn(e)
        }
      }
    })
  }

  const updateSettings = async () => {
    const gpuTier = await getGPUTier()
    Settings.tier = gpuTier.tier

    Settings.mobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry)/) !== null
    Settings.safari = /^((?!chrome).)*safari/i.test(navigator.userAgent)

    if (gpuTier.fps) Settings.fps = gpuTier.fps

    // Safari sucks
    if (Settings.safari) Settings.tier = 1

    // Quality override
    // http://localhost:3000/#quality=high
    const hashQuality = document.location.hash.search('quality')
    if (hashQuality > -1) {
      const qualityHash = document.location.hash.slice(hashQuality + 8).split(',')[0]
      if (qualityHash === Quality.LOW) {
        Settings.tier = 1
      } else if (qualityHash === Quality.MEDIUM) {
        Settings.tier = 2
      } else if (qualityHash === Quality.HIGH) {
        Settings.tier = 3
      }
    }

    switch (Settings.tier) {
      case 3:
        Settings.quality = Quality.HIGH
        break
      case 2:
        Settings.quality = Quality.MEDIUM
        break
      default:
      case 1:
      case 0:
        Settings.quality = Quality.LOW
        break
    }

    // M1 reset
    if (gpuTier.gpu && gpuTier.gpu.search('apple m1') > -1) {
      Settings.set(Quality.HIGH)
    }

    // Override Settings
    // Settings.set(Quality.HIGH)

    if (!detected) {
      beginLoad()
    }
    detected = true
  }

  useEffect(() => {
    manager.current.onStart = onStart
    manager.current.onProgress = onProgress
    manager.current.onLoad = onLoad
  }, [])

  useEffect(() => {
    if (!isEnabled) {
      return
    }

    if (detected) {
      beginLoad()
    } else {
      updateSettings()
    }
  }, [isEnabled])

  return loaderId
}

export default useAssetLoader
