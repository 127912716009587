import { BoxBufferGeometry, Camera, Group, Mesh, MeshBasicMaterial, Raycaster, Texture, Vector2 } from 'three'
import { Settings } from 'hooks/useAssetLoader'
import { app, dispatcher, Events } from '../../global'
import { disposeObject } from '../../utils'
import Arrow from './Arrow'
import RowItem from './RowItem'

export default function Row(name: string, camera: Camera, direction: number, textures: Array<Texture>) {
  // Public
  this.container = new Group()
  this.container.name = name

  this.direction = direction

  this.items = new Group()
  this.items.name = 'items'
  this.container.add(this.items)

  this.clickArea = new Mesh(
    new BoxBufferGeometry(3000, 200, 0.1),
    new MeshBasicMaterial({
      alphaTest: 0.5,
      opacity: 0,
      transparent: true,
    }),
  )
  this.clickArea.name = 'clickArea'
  this.container.add(this.clickArea)

  // Private
  let isOver = false
  let currentRollOver = undefined
  const evtStart = Settings.mobile ? 'touchstart' : 'mousedown'
  const evtEnd = Settings.mobile ? 'touchend' : 'mouseup'
  const config = {
    padding: 0,
    speed: 50,
    down: false,
    pos: new Vector2(),
    rowWidth: 0,
  }

  const raycaster = new Raycaster()
  const touchPoint = new Vector2()

  const checkIntersects = (pt: Touch) => {
    touchPoint.x = (pt.clientX / window.innerWidth) * 2 - 1
    touchPoint.y = -(pt.clientY / window.innerHeight) * 2 + 1
    raycaster.setFromCamera(touchPoint, camera)
    // calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(this.items.children)
    isOver = intersects.length > 0
  }

  const updatePadding = () => {
    const total = this.items.children.length
    let offset = this.items.children[0].position.x
    config.rowWidth = 0
    for (let i = 0; i < total; i++) {
      const child = this.items.children[i] as Mesh
      const width = child.scale.x
      const right = offset + width
      config.rowWidth = Math.max(config.rowWidth, right)
      child.position.x = offset
      offset += width + config.padding
    }
    this.items.position.x = -config.rowWidth / 2
    if (config.rowWidth * 0.4 < window.innerWidth) {
      config.padding += 50
      updatePadding()
    }
  }

  const onDragStart = (evt: any) => {
    if (Settings.mobile) checkIntersects(evt['touches'][0] as Touch)
    if (!isOver) return
    config.down = true
    if (Settings.mobile) {
      const pt = evt['touches'][0] as Touch
      config.pos.set(pt.clientX, pt.clientY)
    } else {
      config.pos.set(evt.clientX, evt.clientY)
    }
    window.addEventListener(evtEnd, onDragEnd, false)
    if (Settings.mobile) window.addEventListener('touchcancel', onDragEnd, false)
  }

  const onDragEnd = () => {
    config.down = false
    config.pos.set(0, 0)
    window.removeEventListener(evtEnd, onDragEnd)
    if (Settings.mobile) window.removeEventListener('touchcancel', onDragEnd)
  }

  const init = () => {
    const offset = this.direction < 0 ? 0 : 3
    let index = 0
    textures.forEach((texture: Texture) => {
      this.items.add(new RowItem(direction, texture, index + offset))
      this.items.add(new Arrow(direction))
      index = (index + 1) % 3
    })
    updatePadding()
    updateWrapping(Math.random() * config.rowWidth)
    window.addEventListener(evtStart, onDragStart, false)
  }

  const rollOver = (obj: RowItem) => {
    if (app.dragging) return
    currentRollOver = obj
    obj.rollOver()
    dispatcher.dispatchEvent({
      type: Events.CURSOR,
      value: obj,
    })
  }

  const rollOut = () => {
    if (app.dragging) return
    currentRollOver.rollOut()
    currentRollOver = undefined
    dispatcher.dispatchEvent({
      type: Events.CURSOR,
      value: undefined,
    })
  }

  const checkRollOver = (intersects: Array<any>) => {
    if (intersects.length > 1) {
      const item = intersects[1]
      const obj = item.object
      // Same object
      if (currentRollOver === obj) return

      // Roll out previous item
      if (currentRollOver !== undefined) rollOut()

      // Roll over current item
      if (obj instanceof RowItem) {
        rollOver(obj)
      }
    } else if (currentRollOver !== undefined) {
      rollOut()
    }
  }

  const updateWrapping = (offset: number) => {
    const total = this.items.children.length
    const rowOffset = config.rowWidth + config.padding
    for (let i = 0; i < total; i++) {
      const child = this.items.children[i] as Mesh
      child.position.x += offset

      // Check for wrapping
      if (child.position.x < -(child.scale.x + config.padding)) {
        child.position.x += rowOffset
      } else if (child.position.x > rowOffset) {
        child.position.x -= rowOffset
      }
    }
  }

  init()

  // Functions

  this.dispose = () => {
    disposeObject(this.container)
    window.removeEventListener(evtStart, onDragStart)
  }

  this.setPosition = (y: number, z: number) => {
    this.container.position.y = y
    this.container.position.z = z
  }

  this.update = (delta: number, mousePos: any, intersects: Array<any>) => {
    let offset = delta * config.speed * direction

    isOver = intersects.length > 0
    checkRollOver(intersects)

    if (config.down) {
      if (!mousePos) return
      const dpr = Settings.mobile ? window.devicePixelRatio : 1
      offset += (mousePos.x - config.pos.x) * dpr
      let yChange = mousePos.y - config.pos.y
      if (isNaN(yChange)) yChange = 0
      if (isNaN(offset)) offset = 0
      if (Math.abs(offset) < Math.abs(yChange)) {
        offset = 0
      }
      config.pos.set(mousePos.x, mousePos.y)
    }

    updateWrapping(offset)
  }
}
