import { useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { RectAreaLight } from 'three'
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper'
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib'
import { debugColor, debugFolder, debugInput } from '../utils/debug'

export default function RectArealightWithHelper({ width, height, color, intensity, name, position }) {
  const { scene } = useThree()
  useEffect(() => {
    RectAreaLightUniformsLib.init()
    const rectLight = new RectAreaLight(color, intensity, width, height)
    rectLight.position.set(position[0], position[1], position[2])
    rectLight.lookAt(0, 0, 0)
    scene.add(rectLight)

    const helper = new RectAreaLightHelper(rectLight)
    helper.visible = false
    scene.add(helper)

    // Debug
    const parentFolder = debugFolder('Lights')
    const folder = debugFolder(`Rect ${name}`, false, parentFolder)
    debugInput(folder, rectLight, 'position', {
      onChange: () => {
        rectLight.lookAt(0, 0, 0)
      },
    })
    debugInput(folder, rectLight, 'width', {
      min: 0,
      max: 5,
      step: 0.01,
    })
    debugInput(folder, rectLight, 'height', {
      min: 0,
      max: 5,
      step: 0.01,
    })
    debugInput(folder, rectLight, 'intensity', {
      min: 0,
      max: 6,
      step: 0.01,
    })
    debugColor(folder, rectLight, 'color')
    debugInput(folder, helper, 'visible', { label: 'Show' })
  }, [])
  return null
}
