import React from 'react'
import RectArealightWithHelper from './RectArealightWithHelper'

export default function Lights() {
  return (
    <>
      <RectArealightWithHelper
        name={'left'}
        color={0xff0d01}
        intensity={5}
        width={1}
        height={1.5}
        position={[-1.5, 0, 0]}
      />
      <RectArealightWithHelper
        name={'right'}
        color={0x0192fd}
        intensity={5}
        width={2.23}
        height={2.5}
        position={[1.5, 0, 0]}
      />
    </>
  )
}
