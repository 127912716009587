const colors = {
  background: '#ffffff',
  red: '#C51638',
  lightGrey: '#F2F1EF',
  mediumGrey: '#D7D7D9',
  darkGrey: '#18191D',

  black: '#000000',
  white: '#ffffff',
  grey_1: '#F0F0F0',
  grey_2: '#BDBDBD',
  grey_3: '#828282',
  grey_4: '#EDECEC',
  grey_5: '#F2F2F2',
  blue_1: '#00C0F0',
  blue_2: '#002F3A',
  blue_3: '#191B36',
  blue_4: '#111329',
  blue_5: '#2755CC',
  blue_6: '#1D1824',
  blue_7: '#190F27',
  blue_8: '#0E2328',
  blue_9: '#1A2139',
  blue_10: '#0B1220',
  blue_11: '#2E327E',
  blue_12: '#131725',
  blue_13: '#0D1116',
  blue_14: '#111523',
  blue_15: '#131621',
  blue_16: '#121520',
  blue_17: '#18182D',
  blue_18: '#0E1F28',
  purple_1: '#9B51E0',
  purple_2: '#8431E8',
  red_1: '#FD545A',
  pink_1: '#E62F9D',
}

export default colors
