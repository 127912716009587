import { rem } from 'polished'
import { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { chakra, Box, List } from '@chakra-ui/react'

import ContainerGrid from 'components/containers/ContainerGrid'
import { GlowStickType } from 'components/GlowStick'
import { MotionBox, MotionListItem } from 'components/Motion'
import Button from 'components/buttons/Button'
import Socials from 'components/Socials'

import { ContentfulGraphRoute, ContentfulGraphSocialLink } from 'contentful/types'
import { ARTBOARD_WIDTH_SM } from 'constants/design'
import { pct } from 'utils/designHelper'
import { ScrollLock } from 'utils/dom'
import colors from 'constants/colors'

interface NavigationOverlayProps {
  links: ContentfulGraphRoute[]
  socials: ContentfulGraphSocialLink[]
  onClickButton: () => void
  className?: string
}

const ArtworkOrbits = dynamic(() => import('components/artwork/ArtworkOrbits'))
const GlowStick = dynamic<GlowStickType>(() => import('components/GlowStick'))

const NavigationOverlay = ({ className, links, socials, onClickButton }: NavigationOverlayProps) => {
  useEffect(() => {
    ScrollLock.lock()

    return () => {
      ScrollLock.unlock()
    }
  }, [])

  return (
    <MotionBox
      className={className}
      pos="fixed"
      top={0}
      left={0}
      w="100%"
      h="100%"
      bgColor="blue_12"
      variants={variantsContainer}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <ArtworkOrbits pos="absolute" top={rem(-250)} right={rem(-36)} w={pct(1069, ARTBOARD_WIDTH_SM)} h="auto" />

      <ContainerGrid pos="relative" h="100%" pt={rem(176)} pb={rem(30)} overflow="auto">
        <GlowStick
          pos="absolute"
          top={rem(190)}
          right={rem(64)}
          w={rem(75)}
          glow={{ color: colors.blue_1 }}
          rotation={{ base: 62 }}
        />

        <GlowStick
          pos="absolute"
          top={rem(445)}
          right={rem(-10)}
          w={rem(140)}
          glow={{ color: colors.red_1 }}
          rotation={{ base: -45 }}
        />

        <GlowStick
          pos="absolute"
          bottom={rem(-42)}
          left={rem(116)}
          w={rem(96)}
          glow={{ color: colors.blue_5 }}
          rotation={{ base: 46 }}
        />

        <List>
          {links?.length &&
            links.map((link, i) => {
              const { route, text } = link

              return (
                <MotionListItem mt={rem(30)} variants={variantsListItem} key={`nav-overlay-list-item-${i}`}>
                  <Button
                    fontFamily="heading"
                    fontSize={rem(48)}
                    letterSpacing="-0.04em"
                    lineHeight={rem(56)}
                    href={route}
                    onClick={onClickButton}
                  >
                    {text}
                  </Button>
                </MotionListItem>
              )
            })}
        </List>

        <Box pt={rem(105)}>
          <Box
            fontFamily="mono"
            fontSize={{ base: rem(10) }}
            letterSpacing={{ base: '0.2em' }}
            lineHeight={{ base: rem(10) }}
            textTransform="uppercase"
          >
            follow
          </Box>
          <Socials mt={{ base: rem(22) }} items={socials} />
        </Box>
      </ContainerGrid>
    </MotionBox>
  )
}

const variantsContainer = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
  },
}

const variantsListItem = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
}

export default chakra(NavigationOverlay)
