import { useRef, useState, useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

import useDocumentReady from 'hooks/useDocumentReady'

export interface RectInterface {
  width: number
  height: number
  x: number
  y: number
}

const useElementRect = (refParam?: React.MutableRefObject<HTMLElement>) => {
  const ref = refParam || useRef<HTMLElement | null>(null)

  const [documentHeight, setDocumentHeight] = useState<number | null>(null)
  const [rect, setRect] = useState<RectInterface | null>(null)

  const isDocumentReady = useDocumentReady()

  useEffect(() => {
    if (!isDocumentReady) {
      return
    }

    function handleResize(entries: any[]) {
      setDocumentHeight(entries[0].target.clientHeight)
    }

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(document.body)

    return () => {
      resizeObserver.disconnect()
    }
  }, [isDocumentReady])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    function handleResize() {
      if (!ref.current || !isDocumentReady) {
        return
      }

      ref.current.style.transform = null

      const rect = ref.current.getBoundingClientRect()
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      const rectPageX = rect.left + scrollLeft
      const rectPageY = rect.top + scrollTop

      setRect({
        width: rect.width,
        height: rect.height,
        x: rectPageX,
        y: rectPageY,
      })
    }

    handleResize()

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(ref.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [documentHeight, isDocumentReady])

  return [rect, ref] as const
}

export default useElementRect
