import { Document } from '@contentful/rich-text-types'

export enum EntryTypes {
  CAREERS = 'careers',
  CONTACT_PAGE = 'contactPage',
  HOME_PAGE = 'homePage',
  PROJECT = 'project',
  SETTINGS = 'globalSettings',
  WHAT_WE_DO = 'whatWeDo',
  WHO_WE_ARE = 'whoWeAre',
  OUR_WORK = 'ourWork',
  NOT_FOUND = 'notFound',
}

type ContentfulSys = {
  id: string
  contentType: {
    sys: {
      id: string
    }
  }
}

type ContentfulTag = {
  sys: {
    type: string
    linkType: string
    id: string
  }
}

type ContentfulMetaData = {
  tags: ContentfulTag[]
}

type ContentfulCareer = {
  sys: ContentfulSys
  title: string
  location: string
  intro: string
  body: string
  notesCollection: {
    items: {
      sys: ContentfulSys
      text: string
    }[]
  }
}

export type ContentfulCareerCategory = {
  sys: ContentfulSys
  title: string
  positionsCollection: { items: ContentfulCareer[] }
}

export type StaticPage = {
  params: {
    slug: string
  }
}

export type ContentfulGraphCTA = {
  metadata: ContentfulMetaData
  name: string
  label: string
  title: string
  textBlock: string
  buttonLabel: string
  link: string
  media: ContentfulGraphAsset
}

export type ContentfulLogo = {
  metadata: ContentfulMetaData
  sys: ContentfulSys
  name: string
  logo: ContentfulGraphAsset
}

export type ContentfulLogoList = {
  metadata: ContentfulMetaData
  sys: ContentfulSys
  label: string
  singleRow: boolean
  itemsCollection: { items: ContentfulLogo[] }
}

export type ContentfulGraphCarousel = {
  metadata: ContentfulMetaData
  description: string
  eyebrow: string
  name: string
  slidesCollection: {
    items: ContentfulGraphSlide[]
  }
}

export type ContentfulWhatWeDoPageProps = {
  metadata: ContentfulMetaData
  name: string
  heroTextBlock: string
  heroEyebrow: string
  storiesEyebrow: string
  storiesTextBlock: string
  clientsEyebrow: string
  clientsHeroTitle: string
  clientsDescription: string
  processEyebrow: string
  processHeroTitle: string
  processSubTitle: string
  processSlideOneEyebrow: string
  processSlideOneText: string
  processCenterText: string
  processSlideTwoEyebrow: string
  processSlideTwoText: string
  seoMetadata: ContentfulGraphSEOMetaData
  cta: ContentfulGraphCTA
  heroTitle: { json: Document }
  metricsCollection: { items: GraphMetric[] }
  heroImage: ContentfulGraphAsset
  storiesStackGalleryCollection: { items: ContentfulGraphAsset[] }
  logoListsCollection: { items: ContentfulLogoList[] }
  heroCarousel: ContentfulGraphCarousel
}

export type ContentfulCareersPageProps = {
  metadata: ContentfulMetaData
  seoMetadata: ContentfulGraphSEOMetaData
  name: string
  careersCollection: { items: ContentfulCareerCategory[] }
  careerListHeading: string
  heroCtaText: string
  heroEyebrow: string
  heroText: string
  heroHeading: string
  heroDesktopImage: ContentfulGraphAsset
  heroMobileImage: ContentfulGraphAsset
  mainText: string
  mainImagesCollection: { items: ContentfulGraphAsset[] }
}

export type ContentfulWhoWeArePageProps = {
  metadata: ContentfulMetaData
  name: string
  heroEyebrow: string
  heroTitle: {
    json: Document
  }
  heroTextBlock: string
  heroCarousel: ContentfulGraphCarousel
  seoMetadata: ContentfulGraphSEOMetaData
  ourTeamEyebrow: string
  ourTeamTitle: string
  ourTeamImagesCollection: { items: ContentfulGraphAsset[] }
  ourTeamTextBlockA: string
  ourTeamTextBlockB: string
  whoWeAreEyebrow: string
  whoWeAreHeroTitle: {
    json: Document
  }
  whoWeAreSubtitle: string
  whoWeAreIntro: string
  whoWeAreBodyLeft: string
  whoWeAreBodyRight: string
  heroImage: ContentfulGraphAsset
  whoWeAreStackGalleryCollection: { items: ContentfulGraphAsset[] }
  whoWeAreTopImagesCollection: { items: ContentfulGraphAsset[] }
  metricsCollection: { items: GraphMetric[] }
  cta: ContentfulGraphCTA
}

export type ContentfulContactMethod = {
  metadata: ContentfulMetaData
  sys: ContentfulSys
  details: string
  label: string
  uri: string
  extraButton: {
    label: string
    uri: string
  }
}

export type ContentfulContactPageProps = {
  metadata: ContentfulMetaData
  seoMetadata: ContentfulGraphSEOMetaData
  eyebrow: string
  headline: string
  contactInfoCollection: { items: ContentfulContactMethod[] }
}

export type ContentfulContactGraphMethod = {
  sys: {
    id: string
  }
  details: string
  label: string
  uri: string
  extraButton: {
    items: {
      label: string
      uri: string
    }[]
  }
}

export type ContentfulGlobalSettings = {
  clientEyebrow: string
  creditsEyebrow: string
  partnersEyebrow: string
  projectOverviewEyebrow: string
  projectOverviewTitle: string
  projectButtonText: string
  relatedProjectEyebrow: string
  socialLabel: string
  relatedProjectTitle: string
  legal: string
  newsletter: {
    errorMessage: string
    successMessage: string
    submitLabel: string
    placeholder: string
    heading: string
  }
  routesCollection: {
    items: ContentfulGraphRoute[]
  }
  socialLinksCollection: {
    items: ContentfulGraphSocialLink[]
  }
  contactInfoCollection: ContentfulContactGraphMethod[]
}

export type ContentfulGraphSocialLink = {
  sys: {
    id: string
  }
  type: string
  url: string
}

export type ContentfulGraphRoute = {
  sys: {
    id: string
  }
  backgroundColor: string
  route: string
  text: string
}

type ContentfulGraphTag = {
  type: string
  id: string
}

// pages
export type ContentfulProjectGraph = {
  title: string
  slug: string
  backgroundColor: string
  heroTextBlockA: string
  overviewBody: string
  overviewIntro: string
  creditsMessage: string
  tags: string[]
  vimeoPlayer: {
    vimeoId: string
    name: string
    thumbnail: ContentfulGraphAsset
  }
  heroMedia: ContentfulGraphAsset
  seoMetadata: ContentfulGraphSEOMetaData
  client: {
    name: string
  }
  filtersCollection: {
    items: ContentfulGraphFilter[]
  }
  relatedProjectsCollection: {
    items: ContentfulGraphRelatedProject[]
  }
  creditsCollection: {
    items: {
      sys: {
        id: string
      }
      name: string
      role: string
    }[]
  }
  agencyPartnersCollection: {
    items: ContentfulGraphPartner[]
  }
  twoColumnBlocksCollection: {
    items: ContentfulGraphTwoColumBlock[]
  }
  carouselCollection: {
    items: ContentfulGraphSlide[]
  }
  contentModulesCollection: {
    items: Array<FlexModuleGraphTypes>
  }
}

export type ContentfulOurWorkGraph = {
  heroEyebrow: string
  name: string
  projectCtaText: string
  seoMetadata: ContentfulGraphSEOMetaData
  squareDisplayLabel: string
  varyingDisplayLabel: string
  filtersCollection: {
    items: ContentfulGraphFilter[]
  }
  projectsCollection: {
    items: ContentfulGraphRelatedProject[]
  }
}

export type ContentfulNotFoundPageGraph = {
  pageTitle: {
    json: Document
  }
  pageSubTitle: string
}

export type ContentfulHomePageGraph = {
  contentfulMetadata: {
    tags: ContentfulGraphTag[]
  }
  seoMetadata: ContentfulGraphSEOMetaData
  whatWeDoBlockA: string
  whatWeDoBlockB: string
  heroSubTitle: string
  heroTitle: {
    json: Document
  }
  whatWeDoButtonText: string
  whatWeDoTitle: string
  whatWeDoTargetUrl: string
  heroTargetUrl: string
  featuredProjectsButtonText: string
  featuredProjectsTargetUrl: string
  featuredProjectsTitle: string
  featuredProjectsCollection: {
    items: ContentfulGraphRelatedProject[]
  }
}

export type FlexModuleGraphTypes =
  | GraphHeadlineModule
  | GraphFullWidthImageModule
  | GraphVimeo
  | GraphCopyColumnsModule
  | GraphStackedCardsModule
  | GraphMetricsModule
  | GraphImageGridModule
  | GraphQuoteModule
  | GraphCopyImageColumnModule
  | GraphVideoPlayer

type GraphSys = {
  id: string
}

export type GraphVideoPlayer = {
  sys: GraphSys
  video: ContentfulGraphAsset
  name: string
  muted: boolean
  loop: boolean
}

export type ContentfulGraphSlide = {
  // metadata: ContentfulMetaData
  sys: {
    id: string
  }
  title: string
  label: string
  image: ContentfulGraphAsset
}

export type GraphHeadlineModule = {
  sys: {
    id: string
  }
  label: string
  headline: string
  bodyText: {
    json: Document
  }
}

export type GraphCopyImageColumnModule = {
  sys: GraphSys
  name: string
  eyebrow: string
  copy: string
  image: ContentfulGraphAsset
}

export type GraphImageGridModule = {
  sys: GraphSys
  name: string
  columns: number
  imagesCollection: {
    items: GraphGridImage[]
  }
}

export type GraphQuoteModule = {
  sys: GraphSys
  quote: string
  quoteAuthor: string
  quoteAuthorTitle: string
}

export type GraphMetric = {
  sys: GraphSys
  metricKey: string
  value: number
  suffix: string
  eyebrow?: string
  infoIntro: string
  infoBody: string
}

export type GraphFullWidthImageModule = {
  sys: {
    id: string
  }
  name: string
  image: ContentfulGraphAsset
}

export type GraphStackedCardsModule = {
  sys: GraphSys
  mediaCollection: {
    items: ContentfulGraphAsset[]
  }
}

export type GraphCopyColumnsModule = {
  sys: GraphSys
  alignRight: boolean
  intro: string
  bodyText: {
    json: Document
  }
  eyebrow: string
}

export type GraphMetricsModule = {
  sys: GraphSys
  metricsCollection: {
    items: GraphMetric[]
  }
  type: string
}

export type GraphGridImage = {
  sys: GraphSys
  image: ContentfulGraphAsset
  offset: number
  columns: number
  name: string
}

export type GraphVimeo = {
  sys: GraphSys
  vimeoId: string
  name: string
  thumbnail: ContentfulGraphAsset
}

export type ContentfulGraphTwoColumBlock = {
  sys: {
    id: string
  }
  title: string
  blockB: string
  blockA: string
  media: ContentfulGraphAsset
  stretchImage: boolean
  layout: string
}

export type ContentfulGraphPartner = {
  sys: {
    id: string
  }
  name: string
}

export type ContentfulGraphCredit = {
  sys: {
    id: string
  }
  name: string
  role: string
}

export type ContentfulGraphAsset = {
  sys: {
    id: string
  }
  contentfulMetadata: {
    tags: {
      id: string
      name: string
    }[]
  }
  url: string
  title: string
  description: string
  fileName: string
  contentType: string
  width: number
  height: number
  size: number
}

export type ContentfulGraphFilter = {
  type: string
  label: string
}

export type ContentfulGraphSEOMetaData = {
  title: string
  description: string
  metaShareImage: ContentfulGraphAsset
  twitterShareImage: ContentfulGraphAsset
}

export type ContentfulGraphRelatedProject = {
  sys: {
    id: string
  }
  title: string
  shortTitle: string
  slug: string
  tags: string[]
  gradient: string[]
  client: {
    name: string
  }
  filtersCollection: {
    items: {
      label: string
      type: string
    }[]
  }
  previewThumbnail: ContentfulGraphAsset
  previewThumbnailSmall: ContentfulGraphAsset
  heroMedia: ContentfulGraphAsset
}

export type ContentfulProjects = {
  fields: {
    slug: string
  }
}

export type TopLevelTypes =
  | ContentfulHomePageGraph
  | ContentfulOurWorkGraph
  | ContentfulProjectGraph
  | ContentfulProjects
  | ContentfulContactPageProps
  | ContentfulWhatWeDoPageProps
  | ContentfulWhoWeArePageProps
  | ContentfulCareersPageProps
