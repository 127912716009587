import { useEffect } from 'react'

const useScrollTicker = (callback: () => void, isEnabled: boolean, deps = []) => {
  useEffect(() => {
    if (!isEnabled) {
      return
    }

    let isTicking = false

    function update() {
      isTicking = false
      callback()
    }

    function handleScroll() {
      if (!isTicking) {
        requestAnimationFrame(update)
      }

      isTicking = true
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [...deps, isEnabled])
}

export default useScrollTicker
