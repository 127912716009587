import dynamic from 'next/dynamic'
import { rem, rgba } from 'polished'
import { useRef, useState } from 'react'
import { chakra, Box } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import { FieldValues, useForm } from 'react-hook-form'

import AnimatedSplitText from 'components/AnimatedSplitText'
import InputOutline from 'components/inputs/InputOutline'
import { MotionIconType } from 'components/common/Icons'
import Button from 'components/buttons/Button'
import { MotionBox } from 'components/Motion'

import useIntersectionObserver from 'hooks/useIntersectionObserver'
import useTimeout from 'hooks/useTimeout'

import colors from 'constants/colors'

interface NewsletterProps {
  text: string
  placeholder: string
  submitLabel: string
  errorMessage: string
  successMessage: string
  className?: string
}

const IconArrow = dynamic<MotionIconType>(() => import('components/common/Icons').then((mod) => mod.IconArrow))

const URL = 'api/newsletter'
const SUCCESS_TYPE = 'success'
const ERROR_TYPE = 'error'

const Newsletter = ({ className, text, placeholder, submitLabel, errorMessage, successMessage }: NewsletterProps) => {
  const refContainer = useRef()

  const [message, setMessage] = useState(null)

  const entry = useIntersectionObserver(refContainer, {})

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  async function handleFormSubmit(values: FieldValues) {
    const response = await fetch(URL, {
      method: 'PUT',
      body: JSON.stringify(values),
    })

    if (response.status >= 400) {
      setMessage({
        type: ERROR_TYPE,
        text: errorMessage,
      })
    }

    if (response.status === 202) {
      setMessage({
        type: SUCCESS_TYPE,
        text: successMessage,
      })
    }
  }

  function handleTimeout() {
    reset()
    setMessage(null)
  }

  useTimeout(handleTimeout, 3000, message !== null)

  const hasError = errors.email?.type === 'required' || message?.type === ERROR_TYPE
  const inputColor = hasError ? colors.red_1 : colors.blue_1

  return (
    <MotionBox
      className={className}
      pt={{ base: rem(32), xl: rem(110) }}
      pb={{ base: rem(42), xl: rem(85) }}
      px={{ base: rem(20), xl: rem(48) }}
      borderTopLeftRadius={{ base: rem(14), xl: rem(40) }}
      borderTopRightRadius={{ base: rem(14), xl: rem(40) }}
      bgColor="blue_3"
      variants={variantsContainer}
      animate={entry?.isIntersecting ? 'animate' : 'initial'}
      ref={refContainer}
    >
      <AnimatedSplitText
        px={{ base: rem(10), xl: rem(46) }}
        fontFamily="headingAlt"
        fontSize={{ base: rem(42), xl: rem(64) }}
        letterSpacing={{ base: rem(-0.35), xl: rem(-1) }}
        lineHeight={{ base: rem(48), xl: rem(73) }}
        textAlign={{ base: 'center', xl: 'left' }}
      >
        {text}
      </AnimatedSplitText>

      <Box
        as="form"
        pos="relative"
        mt={{ base: rem(18), xl: rem(36) }}
        pointerEvents={message ? 'none' : 'auto'}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <MotionBox pos="relative" display="flex" alignItems="center" color="blue_1" variants={variantsContainerInput}>
          <InputOutline
            w="100%"
            color={inputColor}
            borderColor={rgba(inputColor, 0.4)}
            type="email"
            placeholder={placeholder}
            register={register}
            required={true}
            css={`
              &:-webkit-autofill,
              &:-webkit-autofill:hover,
              &:-webkit-autofill:focus,
              &:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 118px ${colors.blue_3} inset !important;
                border-color: ${inputColor};
              }

              &:-webkit-autofill {
                -webkit-text-fill-color: ${inputColor} !important;
              }
            `}
          />

          <AnimatePresence>
            {message && (
              <MotionBox
                pos="absolute"
                top="10px"
                left={{ base: rem(20), xl: rem(42) }}
                display="flex"
                alignItems="center"
                w={{ base: `calc(100% - ${rem(40)})`, xl: `calc(100% - ${rem(84)})` }}
                h="calc(100% - 20px)"
                bgColor="blue_3"
                color={message.type === ERROR_TYPE ? 'red_1' : 'white'}
                fontSize={{ base: rem(20), xl: rem(36) }}
                letterSpacing={{ base: rem(-0.44), xl: rem(-1.25) }}
                lineHeight={{ base: rem(17), xl: rem(50) }}
                variants={variantsMessage}
                animate="animate"
                exit="exit"
              >
                <AnimatedSplitText opacity={0.7}>{message.text}</AnimatedSplitText>
              </MotionBox>
            )}
          </AnimatePresence>

          {errors.email?.type === 'required' && (
            <Box
              pos="absolute"
              top={`calc(100% + ${rem(14)})`}
              left={{ base: rem(24), xl: rem(48) }}
              color={inputColor}
              fontFamily="mono"
              fontSize={{ base: rem(10) }}
              letterSpacing={{ base: '0.2em' }}
              lineHeight={{ base: rem(10) }}
              textTransform="uppercase"
            >
              Email is required
            </Box>
          )}

          {!hasError && (
            <Button
              pos="absolute"
              right={{ base: 0, xl: rem(38) }}
              p={rem(20)}
              color={inputColor}
              aria-label={submitLabel}
            >
              <IconArrow w={{ base: rem(20), xl: rem(32) }} h="auto" variants={variantsArrow} />
            </Button>
          )}
        </MotionBox>
      </Box>
    </MotionBox>
  )
}

const variantsContainer = {
  initial: {
    opacity: 0,
    y: 100,
    transition: {
      duration: 0,
    },
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      opacity: {
        ease: 'linear',
      },
      y: {
        ease: [0.25, 0, 0, 1],
        duration: 1,
      },
    },
  },
  message: {
    opacity: 0,
  },
}

const variantsContainerInput = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
}

const variantsArrow = {
  initial: {
    x: -150,
    transition: {
      duration: 0,
    },
  },
  animate: {
    x: 0,
    transition: {
      ease: [0.25, 0, 0, 1],
      duration: 1,
    },
  },
}

const variantsMessage = {
  animate: {
    opacity: [0, 1],
    transition: {
      ease: 'linear',
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: 'linear',
      duration: 0.3,
    },
  },
}

export default chakra(Newsletter)
