import { useEffect, useRef, useState } from 'react'
import { useThree } from '@react-three/fiber'
import { EffectComposer, SelectiveBloom } from '@react-three/postprocessing'
import { BlendFunction, KernelSize, SelectiveBloomEffect } from 'postprocessing'
import { PointLight } from 'three'
import { Quality, Settings } from 'hooks/useAssetLoader'
import Composite from './Composite'
import { Animations, Events, dispatcher } from '../global'
import { debugColor, debugFolder, debugInput } from '../utils/debug'
import { IS_DEV } from '../../constants'

export default function Post() {
  const { scene } = useThree()
  const pl1Ref = useRef<PointLight>()
  const pl2Ref = useRef<PointLight>()
  const pl3Ref = useRef<PointLight>()
  const pl4Ref = useRef<PointLight>()
  const pl5Ref = useRef<PointLight>()
  const pl6Ref = useRef<PointLight>()
  const pl7Ref = useRef<PointLight>()
  const pl8Ref = useRef<PointLight>()
  // Bloom
  const bloomRef = useRef()
  const [bloomEnabled] = useState(Settings.quality !== Quality.LOW)

  const initDebug = () => {
    const postFolder = debugFolder('Post')
    if (bloomEnabled) {
      const settings = {
        intensity: 1.5,
        smoothing: 0.01,
        threshold: 0,
      }
      const folder = debugFolder('Bloom', false, postFolder)
      debugInput(folder, settings, 'intensity', {
        min: 0,
        max: 3,
        onChange: (value: number) => {
          const bloom = bloomRef.current as SelectiveBloomEffect
          bloom.intensity = value
        },
      })
      debugInput(folder, settings, 'smoothing', {
        min: 0,
        max: 0.1,
        onChange: (value: number) => {
          const bloom = bloomRef.current as SelectiveBloomEffect
          const material = bloom.luminanceMaterial
          material.uniforms.smoothing.value = value
        },
      })
      debugInput(folder, settings, 'threshold', {
        min: 0,
        max: 3,
        onChange: (value: number) => {
          const bloom = bloomRef.current as SelectiveBloomEffect
          const material = bloom.luminanceMaterial
          material.uniforms.threshold.value = value
        },
      })
    }

    // Lights
    const parentFolder = debugFolder('Lights')
    const pl1 = pl1Ref.current as PointLight
    const pl2 = pl2Ref.current as PointLight
    const pl3 = pl1Ref.current as PointLight
    const pl4 = pl2Ref.current as PointLight
    const pl5 = pl1Ref.current as PointLight
    const pl6 = pl2Ref.current as PointLight
    const pl7 = pl1Ref.current as PointLight
    const pl8 = pl2Ref.current as PointLight

    const debugPoint = (name: string, light: PointLight) => {
      const folder = debugFolder(name, false, parentFolder)
      debugInput(folder, light, 'visible')
      debugInput(folder, light, 'distance', {
        min: 0,
        max: 30,
      })
      debugInput(folder, light, 'intensity', {
        min: 0,
        max: 10,
      })
      debugInput(folder, light, 'position')
      debugColor(folder, light, 'color')
    }

    debugPoint('T1', pl1)
    debugPoint('B1', pl2)
    debugPoint('T2', pl3)
    debugPoint('B2', pl4)
    debugPoint('T3', pl5)
    debugPoint('B3', pl6)
    debugPoint('T4', pl7)
    debugPoint('B4', pl8)
  }

  const addedSelectedBloom = () => {
    const bloom = bloomRef.current as SelectiveBloomEffect
    const lights = scene.getObjectByName('lights')
    bloom.selection.add(lights)
  }

  const onAnimation = (evt: any) => {
    if (evt.value === Animations.Intro) {
      dispatcher.removeEventListener(Events.ANIMATION, onAnimation)
      let timer = setTimeout(() => {
        clearTimeout(timer)
        timer = undefined
        addedSelectedBloom()
      }, 100)
    }
  }

  useEffect(() => {
    if (bloomEnabled) {
      dispatcher.addEventListener(Events.ANIMATION, onAnimation)
    }
    if (IS_DEV) initDebug()
    return () => {
      dispatcher.removeEventListener(Events.ANIMATION, onAnimation)
    }
  }, [])

  return (
    <>
      <>
        <pointLight ref={pl1Ref} color={0xff0d01} distance={6} intensity={1.0} position={[-0.9, 1.3, -7.3]} />
        <pointLight ref={pl2Ref} color={0x0192fd} distance={5} intensity={1.0} position={[-0.7, -0.75, -5.7]} />
        <pointLight ref={pl3Ref} color={0xff0d01} distance={6} intensity={0.8} position={[0.9, 1.3, -13]} />
        <pointLight ref={pl4Ref} color={0x0192fd} distance={5} intensity={0.8} position={[0.7, -0.75, -12]} />
        <pointLight ref={pl5Ref} color={0xff0d01} distance={6} intensity={0.6} position={[-0.9, 1.3, -18]} />
        <pointLight ref={pl6Ref} color={0x0192fd} distance={5} intensity={0.6} position={[-0.7, -0.75, -17]} />
        <pointLight ref={pl7Ref} color={0xff0d01} distance={6} intensity={0.4} position={[0.9, 1.3, -23]} />
        <pointLight ref={pl8Ref} color={0x0192fd} distance={5} intensity={0.4} position={[0.7, -0.75, -22]} />
      </>
      <EffectComposer autoClear={false} disableNormalPass={true} stencilBuffer={false}>
        {bloomEnabled ? (
          <SelectiveBloom
            ref={bloomRef}
            lights={[pl1Ref, pl2Ref, pl3Ref, pl4Ref, pl5Ref, pl6Ref, pl7Ref, pl8Ref]}
            height={512}
            blendFunction={BlendFunction.SCREEN}
            dithering={true}
            ignoreBackground={false}
            intensity={1.5}
            kernelSize={KernelSize.LARGE}
            luminanceThreshold={0}
            luminanceSmoothing={0.01}
          />
        ) : null}
        <Composite />
      </EffectComposer>
    </>
  )
}
