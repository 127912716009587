import { rem } from 'polished'
import { useRouter } from 'next/router'
import { chakra } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'

import { MotionBox, MotionList, MotionListItem } from 'components/Motion'
import ButtonDots from 'components/buttons/ButtonDots'
import Button from 'components/buttons/Button'

import useMousePosition from 'hooks/useMousePosition'

import { ContentfulGraphRoute } from 'contentful/types'
import colors from 'constants/colors'

interface NavigationListProps {
  links: ContentfulGraphRoute[]
  isEnabled: boolean
  isVisible: boolean
  isCollapsed: boolean
  isMouseActive: boolean
  onExpand: () => void
  onCollapse: () => void
}

const MOUSE_THRESHOLD = 150
const DELAY = 0.1

const NavigationList = ({
  links,
  isEnabled,
  isVisible,
  isCollapsed,
  isMouseActive,
  onExpand,
  onCollapse,
}: NavigationListProps) => {
  const refListItems = useRef(links.map(() => React.createRef<HTMLLIElement>()))
  const refList = useRef(null)

  const mouse = useMousePosition({ isEnabled })
  const router = useRouter()

  const activeDirectory = `/${router.pathname.split('/')[1]}`

  useEffect(() => {
    if (!mouse || !isMouseActive) {
      return
    }

    const distanceY = mouse.y - window.scrollY
    const isRightHalf = mouse.x > window.innerWidth / 2

    if (distanceY < MOUSE_THRESHOLD && isRightHalf && !isVisible) {
      onExpand()
    }

    if ((distanceY >= MOUSE_THRESHOLD || !isRightHalf) && isVisible) {
      onCollapse()
    }
  }, [mouse, isMouseActive, isVisible])

  return (
    <>
      <ButtonDots
        pos="absolute"
        top={rem(-30)}
        right={rem(12)}
        opacity={0}
        variants={variantsDots}
        animate={isVisible ? 'initial' : 'animate'}
        custom={!isVisible && (links.length + 1) * DELAY}
      />

      <MotionList
        pos="absolute"
        right={0}
        display="flex"
        opacity={0}
        variants={variantsContainer}
        animate={isEnabled && (isCollapsed ? 'collapsed' : 'animate')}
        ref={refList}
      >
        {links.map((link, i) => {
          const { route, text } = link
          const staggerOffset = isVisible ? links.length + 1 - i : i

          return (
            <MotionListItem
              ml={rem(40)}
              variants={variantsListItem}
              animate={isEnabled && (isVisible ? 'initial' : 'hidden')}
              custom={staggerOffset * DELAY}
              ref={refListItems.current[i]}
              key={`nav_link_${i}`}
            >
              <Button
                fontFamily="mono"
                fontSize={rem(13)}
                letterSpacing="0.05em"
                lineHeight={rem(16)}
                textTransform="uppercase"
                pointerEvents={isEnabled ? 'auto' : 'none'}
                variants={variantsButton}
                animate={activeDirectory === route ? 'animate' : 'initial'}
                whileHover="animate"
                href={route}
              >
                {text}

                <MotionBox
                  w={rem(11)}
                  h={rem(2)}
                  mt={rem(10)}
                  bgColor="blue_1"
                  transformOrigin="left"
                  variants={variantsButtonUnderline}
                  initial="initial"
                  animate={activeDirectory === route ? 'animate' : 'initial'}
                />
              </Button>
            </MotionListItem>
          )
        })}
      </MotionList>
    </>
  )
}

const variantsContainer = {
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.25, 0, 0, 1],
      duration: 0.3,
      opacity: {
        ease: 'linear',
      },
    },
  },
  collapsed: {
    opacity: 1,
    y: rem(-30),
    transition: {
      ease: [0.25, 0, 0, 1],
      duration: 0.3,
      opacity: {
        ease: 'linear',
      },
    },
  },
}

const variantsListItem = {
  initial: (delay: number) => ({
    opacity: 1,
    transition: {
      ease: 'linear',
      duration: 0.2,
      delay,
    },
  }),
  hidden: (delay: number) => ({
    opacity: 0,
    transition: {
      ease: 'linear',
      duration: 0.2,
      delay,
    },
  }),
}

const variantsDots = {
  initial: {
    opacity: 0,
    transition: {
      ease: 'linear',
      duration: 0.2,
    },
  },
  animate: (delay: number) => ({
    opacity: 1,
    transition: {
      ease: 'linear',
      duration: 0.3,
      delay,
    },
  }),
}

const variantsButton = {
  initial: {
    color: colors.white,
  },
  animate: {
    color: colors.blue_1,
  },
}

const variantsButtonUnderline = {
  initial: {
    scaleX: 0,
  },
  animate: {
    scaleX: 1,
  },
}

export default chakra(NavigationList)
