import { useState } from 'react'
import { chakra } from '@chakra-ui/react'

import { MotionBox, MotionIcon } from 'components/Motion'

import useElementRect from 'hooks/useElementRect'

interface LogoProps {
  className?: string
}

const Logo = ({ className }: LogoProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const [rectW, refW] = useElementRect()

  function handleMouseEnter() {
    setIsHovered(true)
  }

  function handleMouseLeave() {
    setIsHovered(false)
  }

  return (
    <MotionIcon
      className={className}
      viewBox="0 0 80 31"
      fill="currentcolor"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <g clipPath="url(#logoa)">
        <MotionBox as="g" clipPath="url(#logob)">
          <MotionBox as="g" variants={variantsWild} animate={isHovered ? 'hover' : 'initial'} custom={rectW?.width}>
            <MotionBox as="g" ref={refW}>
              <path d="M0 8.32812H2.50465L5.4476 17.6579H5.88592L8.57841 8.32812H10.269L11.8971 13.7757L10.5821 17.0944L9.3298 13.0243L6.55967 22.6672H4.98424L0 8.32812Z" />
              <path d="M16.2797 8.32812H18.6591L13.8377 22.6672H12.3499L11.834 21.1018L16.2797 8.32812Z" />
            </MotionBox>
            <path d="M20.9135 8.32812H23.606L18.9724 22.6672H16.2373L20.9135 8.32812Z" />
            <path d="M28.8034 0H31.308L24.0019 22.667H21.3721L28.8034 0Z" />
            <path d="M29.7627 12.9417L31.0977 8.8516C31.0977 8.8516 31.5986 8.22544 33.978 8.26552C36.3574 8.30559 37.0662 9.07953 37.0662 9.07953L36.3574 11.5641C36.3574 11.5641 35.8565 10.7301 33.6875 10.7301C31.015 10.7301 29.7627 12.9417 29.7627 12.9417Z" />
            <path d="M42.4541 0H45.2092L37.9032 22.6595H32.1851C32.1851 22.6595 31.058 22.5368 29.3874 21.3246C27.7168 20.1123 27.7168 19.2382 27.7168 19.2382L28.9691 15.0629C28.9691 15.0629 29.0342 19.1505 32.393 20.0297C34.9402 20.6984 36.2752 19.4461 36.2752 19.4461L42.4541 0Z" />
          </MotionBox>
        </MotionBox>

        <MotionBox as="g" clipPath="url(#logoc)">
          <MotionBox as="g" variants={variantsLife} animate={isHovered ? 'hover' : 'initial'}>
            <path d="M47.651 0H50.1557L42.8496 22.667H40.2197L47.651 0Z" />
            <path d="M52.5673 8.32812L48.0589 22.6672H45.2412L50 8.32812H52.5673Z" />
            <path d="M70.1627 10.5197L66.3431 22.6672H63.5254L68.2842 8.32812H79.2245L78.4881 10.5197H70.1627Z" />
            <path d="M76.4794 16.3155L77.2959 13.896H70.9917L70.2178 16.3155" />
            <path d="M74.3632 22.667L75.1797 20.2476H68.878L68.1016 22.667" />
            <path d="M63.2352 16.2876L64.0518 13.8706H57.7476L56.9736 16.2876" />
            <path d="M55.1348 8.30273L47.7861 30.9998H50.5838L57.146 10.4943H65.246L65.9824 8.30273H55.1348Z" />
          </MotionBox>
        </MotionBox>
      </g>

      <defs>
        <clipPath id="logoa">
          <rect width="79.2244" height="31" fill="white" />
        </clipPath>

        <clipPath id="logob">
          <polygon points="0 0 45.5 0 38 23 0 23" />
        </clipPath>

        <clipPath id="logoc">
          <rect width="40" height="31" x="40" y="0" />
        </clipPath>
      </defs>
    </MotionIcon>
  )
}

const variantsWild = {
  initial: {
    x: 0,
    transition: {
      ease: [0.5, 0, 0, 1],
    },
  },
  hover: (widthW: number) => ({
    x: widthW ? `calc(50% - ${widthW - 1}px)` : '0%',
    transition: {
      ease: [0.5, 0, 0, 1],
    },
  }),
}

const variantsLife = {
  initial: {
    x: 0,
    transition: {
      ease: [0.5, 0, 0, 1],
    },
  },
  hover: {
    x: '-50%',
    transition: {
      ease: [0.5, 0, 0, 1],
    },
  },
}

export default chakra(Logo)
