import { useState, useEffect } from 'react'

interface MouseInterface {
  x: number
  y: number
}

interface MousePositionProps {
  isEnabled?: boolean
}

const useMousePosition = ({ isEnabled = false }: MousePositionProps) => {
  const [mouse, setMouse] = useState<MouseInterface | null>(null)

  useEffect(() => {
    function handleMouseMove(e: MouseEvent) {
      setMouse({
        x: e.pageX,
        y: e.pageY,
      })
    }

    if (isEnabled) {
      window.addEventListener('mousemove', handleMouseMove)
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [isEnabled])

  return isEnabled ? mouse : null
}

export default useMousePosition
