import { EventDispatcher, MathUtils, PlaneBufferGeometry } from 'three'
import { TweenController } from './controllers/Tween'
import { anchorGeometry } from './utils'

export enum Events {
  LOAD_COMPLETE = 'loadComplete',
  ANIMATION = 'animation',
  KEY = 'key',
  ENTER_HEADER = 'enterHeader',
  ENTER_FOOTER = 'enterFooter',
  CURSOR = 'cursor',
  URL_UPDATE = 'urlUpdate',
}

export enum Animations {
  None = 'None',
  Intro = 'Intro',
  Idle = 'Idle',
  Mindset = 'Mindset',
  Falling = 'Falling',
  Flip = 'Flip',
  Guitar = 'Guitar',
  Swim = 'Swim',
  Waving = 'Waving',
  // Keyboard animations
  Celery = 'Celery',
  Konami = 'Konami',
  Wildlife = 'Wildlife',
}

function WebGLApp() {
  this.footerVisible = false
  this.headerVisible = false
  this.percent = 0
  this.dragging = false
  this.loaded = false
  this.currentPage = MathUtils.generateUUID()
}

export const dispatcher = new EventDispatcher()
export const tweener = new TweenController()
export const app = new WebGLApp()
export const planeGeometry = new PlaneBufferGeometry()
anchorGeometry(planeGeometry, 0.5, 0, 0)
