import {
  BufferGeometry,
  Camera,
  Float32BufferAttribute,
  LinearFilter,
  Material,
  Matrix4,
  Mesh,
  Object3D,
  OrthographicCamera,
  PositionalAudio,
  Scene,
  Texture,
  Vector2,
  Vector3,
  WebGLRenderer,
  WebGLRenderTarget,
} from 'three'

export const orthoCamera = new OrthographicCamera(-0.5, 0.5, 0.5, -0.5, 0, 1)
export const triangle = new BufferGeometry()
triangle.setAttribute('position', new Float32BufferAttribute([-0.5, -0.5, 0, 1.5, -0.5, 0, -0.5, 1.5, 0], 3))
triangle.setAttribute('normal', new Float32BufferAttribute([0, 0, 1, 0, 0, 1], 3))
triangle.setAttribute('uv', new Float32BufferAttribute([0, 0, 2, 0, 0, 2], 2))

// Dispose texture
export const disposeTexture = (texture: Texture | null) => {
  texture?.dispose()
}

// Dispose material
export const disposeMaterial = (material: Material) => {
  if (!material) return

  let materialArr = []

  if (Array.isArray(material)) {
    materialArr = material
  } else {
    materialArr[0] = material
  }

  materialArr.forEach((materialElement) => {
    const {
      alphaMap,
      displacementMap,
      emissiveMap,
      envMap,
      lightMap,
      map,
      bumpMap,
      aoMap,
      metalnessMap,
      roughnessMap,
      normalMap,
    } = materialElement

    disposeTexture(alphaMap)
    disposeTexture(displacementMap)
    disposeTexture(emissiveMap)
    disposeTexture(envMap)
    disposeTexture(lightMap)
    disposeTexture(map)
    disposeTexture(bumpMap)
    disposeTexture(aoMap)
    disposeTexture(metalnessMap)
    disposeTexture(roughnessMap)
    disposeTexture(normalMap)
    materialElement?.dispose()
  })
}

// Dispose object
export const disposeObject = (object: Object3D | Mesh) => {
  if (!object) return

  // Dispose children
  while (object.children.length > 0) {
    const child = object.children[0]
    if (child instanceof PositionalAudio) {
      child.pause()
      if (child.parent) {
        child.parent.remove(child)
      }
    } else {
      disposeObject(child)
    }
  }

  // Dispose object
  if (object.parent) object.parent.remove(object)
  if (object['isMesh']) {
    object['geometry'].dispose()
    disposeMaterial(object['material'])
  }
}

export function RTT(material: Material) {
  this.camera = orthoCamera
  this.scene = new Scene()
  this.material = material
  this.scene.add(new Mesh(triangle, this.material))

  this.render = (renderer: WebGLRenderer, target: WebGLRenderTarget) => {
    renderer.setRenderTarget(target)
    renderer.render(this.scene, this.camera)
    renderer.setRenderTarget(null)
  }
}

export async function svgToTexture(svg: string, imgWid: number, imgHei: number): Promise<Texture> {
  return new Promise((resolve) => {
    const svgData = [svg]
    const svgBlob = new Blob(svgData, { type: 'image/svg+xml' })

    let texture: Texture
    const img = new Image()
    img.onload = () => {
      texture = new Texture(img)
      texture.anisotropy = 1
      texture.minFilter = LinearFilter
      texture.magFilter = LinearFilter
      texture.needsUpdate = true
      resolve(texture)
    }

    let dataBase64: any
    const reader = new FileReader()
    reader.onload = () => {
      dataBase64 = reader.result
      img.src = dataBase64
      img.width = imgWid
      img.height = imgHei
    }
    dataBase64 = reader.readAsDataURL(svgBlob)
  })
}

export function anchorGeometry(geometry: BufferGeometry, x: number, y: number, z: number) {
  geometry.applyMatrix4(new Matrix4().makeTranslation(x, -y, -z))
}

export function objectTo2D(object: Object3D, camera: Camera): Vector2 {
  camera.updateMatrix()
  camera.updateMatrixWorld()
  object.updateMatrixWorld()

  const width = window.innerWidth,
    height = window.innerHeight,
    widthHalf = width / 2,
    heightHalf = height / 2,
    vector = new Vector3().setFromMatrixPosition(object.matrixWorld)
  vector.project(camera)
  return new Vector2(vector.x * widthHalf + widthHalf, -(vector.y * heightHalf) + heightHalf)
}
