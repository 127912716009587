import { Texture } from 'three'

export function fileName(path: string): string {
  const slash = path.lastIndexOf('/') + 1
  const period = path.lastIndexOf('.')
  return path.substring(slash, period)
}

export function fileType(path: string): string {
  return path.substring(path.lastIndexOf('.') + 1)
}

export async function loadImageElement(path: string) {
  return new Promise((resolve, reject) => {
    const request = new Image()
    request.addEventListener(
      'error',
      () => {
        reject()
      },
      false,
    )
    request.addEventListener(
      'load',
      () => {
        resolve(request)
      },
      false,
    )
    request.src = path
  })
}

export async function loadJSON(path: string, onProgress: (progress: number) => void): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    this.loadXHR(path, 'json', onProgress)
      .then((request: XMLHttpRequest) => {
        let json = request.response
        if (typeof json === 'string') {
          json = JSON.parse(json)
        }
        resolve(json)
      })
      .catch(reject)
  })
}

export function saveImage(width: number, height: number, fileName: string, src: string) {
  const canvas: HTMLCanvasElement = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const context: CanvasRenderingContext2D = canvas.getContext('2d')
  const image = new Image()
  image.onload = () => {
    context.drawImage(image, 0, 0)
    const url = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    // document.location.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    const link = document.createElement('a')
    document.body.appendChild(link)
    link.setAttribute('type', 'hidden')
    // link.href = 'data:text/plain;base64,' + base64;
    link.href = url
    link.download = fileName
    link.click()
    document.body.removeChild(link)
  }
  image.src = src
}

export function saveTexture(texture: Texture, fileName: string) {
  const width = texture.source.data.width
  const height = texture.source.data.height
  const json = texture.source.toJSON(undefined)
  saveImage(width, height, fileName, json.url)
}

export const ScrollLock = (() => {
  return {
    lock() {
      document.body.style.top = `${-window.pageYOffset}px`
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
    },
    unlock() {
      const scrollValue = parseInt(document.body.style.top, 10)

      document.body.style.top = null
      document.body.style.position = null
      document.body.style.width = null

      window.scrollTo(0, scrollValue * -1)
    },
  }
})()
