import { em } from 'polished'
import { mapValues } from 'lodash'
import { createBreakpoints } from '@chakra-ui/theme-tools'
import { BaseBreakpointConfig } from '@chakra-ui/theme-tools/src/create-breakpoints'

export interface BreakpointKeysInterface {
  base: string
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
  xxl: string
}

export const BREAKPOINT_KEYS: BreakpointKeysInterface = {
  base: 'base',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
}

const breakpoints = {
  sm: 375,
  md: 577,
  lg: 768,
  xl: 993,
  '2xl': 1200,
  '3xl': 1440,
}

// em
export const breakpointsEm: BaseBreakpointConfig = createBreakpoints(mapValues(breakpoints, (value) => em(value)))

export default breakpoints
