import React, { createContext, useState, useContext, ReactNode } from 'react'

interface AppContextProviderProps {
  children: ReactNode
}

type AppStateType = {
  route: string
  isNavigationDarkTheme: boolean
} | null
type AppDispatchType = React.Dispatch<React.SetStateAction<AppStateType>> | null

const AppContext = createContext<AppStateType>(null)
const AppDispatchContext = createContext<AppDispatchType>(null)

const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [state, setState] = useState<AppStateType>(null)

  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={setState}>{children}</AppDispatchContext.Provider>
    </AppContext.Provider>
  )
}

export function useAppContext() {
  const state = useContext(AppContext)

  if (typeof state === 'undefined') {
    throw new Error('useAppContext must be used within a AppContextProvider')
  }

  return state
}

export function useAppDispatchContext() {
  const setState = useContext(AppDispatchContext)

  if (setState === null) {
    return
  }

  if (typeof setState === 'undefined') {
    throw new Error('useAppDispatchContext must be used within a AppContextProvider')
  }

  return {
    changeRoute: (route: AppStateType['route']) => {
      setState((prevState) => ({ ...prevState, route }))
    },
    setNavigationDarkTheme: (isNavigationDarkTheme: AppStateType['isNavigationDarkTheme']) => {
      setState((prevState) => ({ ...prevState, isNavigationDarkTheme }))
    },
  }
}

export default AppContextProvider
