import { Group, Mesh, MeshStandardMaterial, InstancedMesh, Object3D } from 'three'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import { useEffect, useRef, useState } from 'react'
import { debugFolder, debugInput } from '../utils/debug'
import { getAsset } from 'hooks/useAssetLoader'
import { useFrame } from '@react-three/fiber'
import { clamp, distance } from 'utils/math'
import { IS_DEV } from '../../constants'

class Hallway extends Object3D {
  offset = 0

  private lights!: InstancedMesh
  private rails!: InstancedMesh

  init() {
    const gltf = getAsset('hallway', 'homePage').data as GLTF
    const lightsRef = gltf.scene.getObjectByName('lights') as Mesh
    const lightBox = lightsRef.geometry.boundingBox
    const lightSize = distance(lightBox.min.z, lightBox.max.z)

    const railsRef = gltf.scene.getObjectByName('rails') as Mesh
    const railsBox = railsRef.geometry.boundingBox
    const railsSize = distance(railsBox.min.z, railsBox.max.z)

    this.offset = Math.max(lightSize, railsSize)
    const total = 3
    const dummy = new Object3D()

    this.lights = new InstancedMesh(lightsRef.geometry, lightsRef.material, total)
    this.lights.name = 'lights'
    this.add(this.lights)

    const railsMaterial = (railsRef.material as MeshStandardMaterial).clone()
    railsMaterial.depthWrite = true
    railsMaterial.metalness = 0.9
    railsMaterial.metalnessMap = null
    railsMaterial.roughness = 0.4
    railsMaterial.roughnessMap = null
    railsMaterial.needsUpdate = true
    this.rails = new InstancedMesh(railsRef.geometry, railsMaterial, total)
    this.rails.name = 'rails'
    this.add(this.rails)

    // Lights
    for (let i = 0; i < total; i++) {
      dummy.position.copy(lightsRef.position)
      dummy.position.z += -i * this.offset
      dummy.position.z += this.offset
      dummy.rotation.copy(lightsRef.rotation)
      dummy.updateMatrix()
      this.lights.setMatrixAt(i, dummy.matrix)
    }

    // Rails
    for (let i = 0; i < total; i++) {
      dummy.position.copy(railsRef.position)
      dummy.position.z += -i * this.offset
      dummy.position.z += this.offset
      dummy.rotation.copy(railsRef.rotation)
      dummy.updateMatrix()
      this.rails.setMatrixAt(i, dummy.matrix)
    }
  }

  initDebug() {
    const folder = debugFolder('Scene')
    const container = this.parent
    const params = {
      containerScale: container.scale.x,
    }
    debugInput(folder, container, 'position')
    debugInput(folder, params, 'containerScale', {
      min: 0.1,
      max: 10,
      onChange: (value: number) => {
        container.scale.setScalar(value)
      },
    })

    // Lights
    debugInput(folder, this.lights, 'position', {
      label: 'lightsPos',
    })

    // Rails
    debugInput(folder, this.rails, 'position', {
      label: 'railsPos',
    })
  }
}

export default function Scene() {
  const groupRef = useRef<Group>()
  const [hallway] = useState(() => new Hallway())

  useEffect(() => {
    const container = groupRef.current as Group
    hallway.init()
    container.add(hallway)
    if (IS_DEV) hallway.initDebug()
  }, [])

  useFrame((_, delta) => {
    const speed = 0.2
    const time = Math.min(delta, 1 / 30)
    hallway.position.z -= time * speed
    if (hallway.position.z < -hallway.offset) {
      hallway.position.z += hallway.offset
    }
    hallway.position.z = clamp(-hallway.offset, 0, hallway.position.z)
  })

  return <group name="props" ref={groupRef} position={[0, -0.5, -2.5]} scale={[7, 7, 7]}></group>
}
