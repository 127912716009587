import { AssetTypes } from 'hooks/useAssetLoader'

export const HOME_ASSETS = [
  {
    key: 'astronaut',
    src: '/models/astronaut.glb',
    type: AssetTypes.GLTF,
  },
  {
    key: 'hallway',
    src: '/models/hallway.glb',
    type: AssetTypes.GLTF,
  },
  {
    key: 'arrow',
    src: '/images/arrow.png',
    type: AssetTypes.Texture,
  },
  {
    key: 'specs',
    src: '/images/specs.png',
    type: AssetTypes.Texture,
  },
  {
    key: 'w-vector',
    src: '/images/w-vector.png',
    type: AssetTypes.Texture,
  },
  {
    key: 'wwd-overlay',
    src: '/images/wwd-overlay.jpg',
    type: AssetTypes.Texture,
  },
]
