import { Box } from '@chakra-ui/react'

const NoiseOverlay = () => {
  return (
    <Box
      pos="fixed"
      top="0"
      left="0"
      zIndex="noise"
      w="100%"
      h="100%"
      backgroundSize="100px"
      backgroundImage="/images/noise.png"
      backgroundRepeat="repeat"
      pointerEvents="none"
    />
  )
}

export default NoiseOverlay
