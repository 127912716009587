import { rem } from 'polished'
import { useRef, useState } from 'react'
import { chakra, Box, As } from '@chakra-ui/react'

import { MotionBox } from 'components/Motion'

import useIntersectionObserver from 'hooks/useIntersectionObserver'

import { splitText } from 'utils/miscHelper'
import { clamp } from 'utils/math'

interface AnimatedSplitTextProps {
  children: string
  className?: string
  perCharacter?: boolean
  startPercentage?: string
  tag?: As
}

const WORDS_LENGTH_LIMIT = 10
const CHARACTERS_LENGTH_LIMIT = 30
const MAX_DURATION_LIMITER = 0.4
const MAX_DELAY_LIMITER = 0.07
const DEFAULT_DURATION = 0.8
const DEFAULT_DELAY = 0.08

const AnimatedSplitText = ({
  children = '',
  className,
  perCharacter,
  tag,
  startPercentage,
  ...props
}: AnimatedSplitTextProps) => {
  const ref = useRef()

  const [animatedSplitText] = useState(splitText(children, perCharacter))

  const entry = useIntersectionObserver(ref, {})

  const limit = perCharacter ? CHARACTERS_LENGTH_LIMIT : WORDS_LENGTH_LIMIT
  const limitFactor = clamp(0, 1, animatedSplitText.length / limit)

  return (
    <MotionBox as={tag || 'span'} className={className} display="flex" flexWrap="wrap" {...props} ref={ref}>
      {animatedSplitText.map((text, index) => (
        <Box as="span" display="inline-block" whiteSpace="pre" overflow="hidden" m={rem(-15)} p={rem(15)} key={index}>
          <MotionBox
            as="span"
            display="inline-block"
            variants={variantsText}
            initial="initial"
            animate={entry?.isIntersecting ? 'animate' : 'initial'}
            custom={{
              delay: (DEFAULT_DELAY - limitFactor * MAX_DELAY_LIMITER) * index,
              duration: DEFAULT_DURATION - limitFactor * MAX_DURATION_LIMITER,
              startPercentage: startPercentage || '100%',
            }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </Box>
      ))}
    </MotionBox>
  )
}

const variantsText = {
  initial: ({ startPercentage }) => ({
    y: startPercentage,
    opacity: 0,
    transition: {
      duration: 0,
    },
  }),
  animate: ({ delay, duration }) => ({
    y: '0%',
    opacity: 1,
    transition: {
      delay,
      opacity: {
        ease: 'linear',
        duration: 0.2,
      },
      y: {
        ease: [0.25, 0, 0, 1],
        duration,
      },
    },
  }),
}

export default chakra(AnimatedSplitText)
