import { Mesh, Texture } from 'three'
import { planeGeometry } from '../../global'
import FooterUIMaterial from '../../materials/FooterUIMaterial'
import { Animations, dispatcher, Events } from '../../global'

const animations = [
  Animations.Mindset,
  Animations.Falling,
  Animations.Waving,
  Animations.Swim,
  Animations.Flip,
  Animations.Guitar,
]

export default class RowItem extends Mesh {
  constructor(direction: number, texture: Texture, index: number) {
    const material = new FooterUIMaterial(texture)
    material.thickness = direction > 0 ? 2.2 : 2.5
    super(planeGeometry, material)
    this.name = `item_${texture.name}`
    this.scale.set(texture.image.width, texture.image.height, 1)
    this.userData['index'] = index
  }

  rollOver() {
    const index = this.userData['index']
    const animation = animations[index]
    const material = this.material as FooterUIMaterial
    material.rollOver()
    dispatcher.dispatchEvent({
      type: Events.ANIMATION,
      value: animation,
    })
  }

  rollOut() {
    const material = this.material as FooterUIMaterial
    material.rollOut()
  }
}
