import { useCallback, useEffect, useState } from 'react'
import { Animations, dispatcher, Events } from '../global'

type Command = {
  name: string
  code: Array<number>
  callback: () => void
}

const commands: Array<Command> = [
  {
    name: Animations.Celery,
    code: [52, 68, 51, 68, 51, 68, 51],
    callback: () => {
      dispatcher.dispatchEvent({
        type: Events.ANIMATION,
        value: Animations.Celery,
      })
    },
  },
  {
    name: Animations.Konami,
    code: [38, 38, 40, 40, 37, 39, 37, 39, 66, 65],
    callback: () => {
      dispatcher.dispatchEvent({
        type: Events.ANIMATION,
        value: Animations.Konami,
      })
    },
  },
  {
    name: Animations.Wildlife,
    code: [87, 73, 76, 68, 76, 73, 70, 69],
    callback: () => {
      dispatcher.dispatchEvent({
        type: Events.ANIMATION,
        value: Animations.Wildlife,
      })
    },
  },
]
const totalCommands = commands.length
let maxLength = 0
commands.forEach((command) => {
  maxLength = Math.max(maxLength, command.code.length)
})

export default function KeyController() {
  const [input, setInput] = useState<number[]>([])

  const onKey = useCallback(
    (evt: KeyboardEvent) => {
      const newInput = input
      newInput.push(evt.keyCode)
      newInput.splice(-maxLength - 1, input.length - maxLength)
      setInput(newInput)
      //
      for (let i = 0; i < totalCommands; i++) {
        const command = commands[i]
        if (newInput.join('').includes(command.code.join(''))) {
          command.callback()
          setInput([])
          break
        }
      }
    },
    [input, setInput],
  )

  useEffect(() => {
    document.addEventListener('keydown', onKey)
    return () => {
      document.removeEventListener('keydown', onKey)
    }
  }, [onKey])

  return null
}
