import { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import TransitionWipe from 'components/TransitionWipe'

import { useAppContext } from 'context/AppContext'
import { useRouter } from 'next/router'

interface ContainerTransitionProps {
  children: React.ReactNode
}

const ContainerTransition = ({ children }: ContainerTransitionProps) => {
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [newRoute, setNewRoute] = useState(null)

  const context = useAppContext()
  const router = useRouter()

  function handleTransitionComplete() {
    router.push(newRoute)
  }

  useEffect(() => {
    if (newRoute) {
      setIsTransitioning(true)
    }
  }, [newRoute])

  useEffect(() => {
    if (context?.route) {
      setNewRoute(context?.route)
    }
  }, [context?.route])

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }

    router.beforePopState(({ as, options }) => {
      options.scroll = false
      setNewRoute(as)

      // return true
      return false
    })
  }, [])

  useEffect(() => {
    function handleRouteChangeComplete() {
      window.scrollTo({ top: 0 })

      setIsTransitioning(false)
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [])

  return (
    <>
      {children}

      <AnimatePresence>
        {isTransitioning && <TransitionWipe onTranstionComplete={handleTransitionComplete} />}
      </AnimatePresence>
    </>
  )
}

export default ContainerTransition
