import { useEffect, useState } from 'react'
import { Euler, MathUtils, Object3D, PerspectiveCamera, Quaternion, Vector3 } from 'three'
import { SimplexNoise } from 'three/examples/jsm/math/SimplexNoise'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { useFrame, useThree } from '@react-three/fiber'
import { clamp, mix, normalize } from '../../utils/math'
import { debugFolder, debugInput } from '../utils/debug'
import useMousePosition from 'hooks/useMousePosition'
import { Animations, app, dispatcher, Events, tweener } from '../global'
import { Settings } from 'hooks/useAssetLoader'
import { IS_DEV } from '../../constants'

function ConfigParams() {
  this.mouse = 0
  this.orbit = false
  this.rotateX = 10
  this.rotateY = 10
  this.shake = 0
  this.maxYaw = 0.05
  this.maxPitch = 0.05
  this.maxRoll = 0.02
  this.yawFrequency = 0.2
  this.pitchFrequency = 0.1
  this.rollFrequency = 0.1
}

const currentRotation = new Euler()
const targetQuat = new Quaternion()
const cameraContainer = new Object3D()
let controls: OrbitControls

const headerStartPos = new Vector3(0, -0.09356853, 4.235418)
const headerEndPos = headerStartPos.clone().sub(new Vector3(0, 2, -4))
const footerEndPos = headerStartPos.clone()
const footerStartPos = footerEndPos.clone().sub(new Vector3(0, -2, -4))
const introPos = headerStartPos.clone().add(new Vector3(0, 0, 2))
const ease = 0.9
const speed = 5
let introComplete = false

export default function CameraController() {
  const { camera, gl, scene } = useThree()
  const cam = camera as PerspectiveCamera

  // Shake
  const [yawNoise] = useState(() => new SimplexNoise())
  const [pitchNoise] = useState(() => new SimplexNoise())
  const [rollNoise] = useState(() => new SimplexNoise())
  const [config] = useState(() => new ConfigParams())

  const resize = () => {
    const width = window.innerWidth
    const height = window.innerHeight
    const aspect = width / height
    cam.aspect = aspect
    cam.updateProjectionMatrix()
    gl.setSize(width, height)
  }

  function initDebug() {
    const folder = debugFolder('Camera')
    debugInput(folder, cam, 'position', {
      x: {
        min: -20,
        max: 20,
      },
      y: {
        min: -20,
        max: 20,
      },
      z: {
        min: -20,
        max: 20,
      },
    })
    debugInput(folder, cam, 'rotation', {
      x: {
        min: -Math.PI,
        max: Math.PI,
      },
      y: {
        min: -Math.PI,
        max: Math.PI,
      },
      z: {
        min: -Math.PI,
        max: Math.PI,
      },
    })
    debugInput(folder, cam, 'fov', {
      min: 1,
      max: 120,
      onChange: () => {
        cam.updateProjectionMatrix()
      },
    })
    debugInput(folder, config, 'rotateX', {
      min: -180,
      max: 180,
      label: 'Mouse Y',
    })
    debugInput(folder, config, 'rotateY', {
      min: 0,
      max: 90,
      label: 'Mouse X',
    })
    debugInput(folder, config, 'mouse', {
      label: 'mouse',
    })
    debugInput(folder, config, 'orbit', {
      onChange: (value: boolean) => {
        controls.enabled = value
        cameraContainer.rotation.set(0, 0, 0)
      },
    })

    // Shake
    const shakeFolder = debugFolder('Shake', false, folder)
    debugInput(shakeFolder, config, 'shake', {
      onChange: (value: boolean) => {
        if (!value) {
          camera.rotation.set(0, 0, 0)
        }
      },
    })
    debugInput(shakeFolder, config, 'maxYaw', {
      min: 0,
      max: 0.3,
      step: 0.01,
      label: 'Max Yaw',
    })
    debugInput(shakeFolder, config, 'yawFrequency', {
      min: 0,
      max: 0.3,
      step: 0.01,
      label: 'Yaw Frequency',
    })
    debugInput(shakeFolder, config, 'maxPitch', {
      min: 0,
      max: 0.3,
      step: 0.01,
      label: 'Max Pitch',
    })
    debugInput(shakeFolder, config, 'pitchFrequency', {
      min: 0,
      max: 0.3,
      step: 0.01,
      label: 'Pitch Frequency',
    })
    debugInput(shakeFolder, config, 'maxRoll', {
      min: 0,
      max: 0.3,
      step: 0.01,
      label: 'Max Roll',
    })
    debugInput(shakeFolder, config, 'rollFrequency', {
      min: 0,
      max: 0.3,
      step: 0.01,
      label: 'Roll Frequency',
    })
  }

  const enterHeader = () => {
    cameraContainer.rotation.set(0, 0, 0)
    if (introComplete && !Settings.mobile) {
      camera.position.copy(app.percent > 0.5 ? headerEndPos : headerStartPos)
    }
  }

  const enterFooter = () => {
    cameraContainer.rotation.set(0, 0, 0)
    if (introComplete && !Settings.mobile) {
      camera.position.copy(app.percent > 0.5 ? footerEndPos : footerStartPos)
    }
  }

  const onAnimation = (evt: any) => {
    if (evt.value === Animations.Intro) {
      const ease = [0.75, 0, 0.25, 1]
      cam.position.copy(introPos)
      camera.rotation.set(0, 0, 0)
      cameraContainer.rotation.set(0, 0, 0)
      config.mouse = 0
      config.shake = 0
      const duration = 1.7

      tweener.to(cam.position, duration, {
        z: headerStartPos.z,
        ease: ease,
        onComplete: () => {
          introComplete = true
          tweener.to(config, 1, { mouse: 1, shake: 1 })
        },
      })

      tweener.to(cam, duration, {
        fov: Settings.fov,
        ease: ease,
        onUpdate: () => {
          cam.updateProjectionMatrix()
        },
      })
    }
  }

  useEffect(() => {
    if (camera.parent === null) {
      controls = new OrbitControls(camera, gl.domElement)
      controls.enabled = config.orbit
      scene.add(cameraContainer)
      cameraContainer.add(camera)
    }

    camera.position.copy(introPos)

    dispatcher.addEventListener(Events.ANIMATION, onAnimation)
    dispatcher.addEventListener(Events.ENTER_HEADER, enterHeader)
    dispatcher.addEventListener(Events.ENTER_FOOTER, enterFooter)
    window.addEventListener('resize', resize, false)
    if (IS_DEV) initDebug()
    return () => {
      dispatcher.removeEventListener(Events.ANIMATION, onAnimation)
      dispatcher.removeEventListener(Events.ENTER_HEADER, enterHeader)
      dispatcher.removeEventListener(Events.ENTER_FOOTER, enterFooter)
      window.removeEventListener('resize', resize)
    }
  }, [])

  // Update
  if (!Settings.mobile) {
    const position = useMousePosition({ isEnabled: true })
    useFrame((state, delta) => {
      if (!config.orbit) {
        if (app.headerVisible) {
          if (introComplete) {
            const time = delta * speed
            const targetPos = new Vector3()
            targetPos.lerpVectors(headerStartPos, headerEndPos, app.percent)
            camera.position.set(
              MathUtils.damp(camera.position.x, targetPos.x, ease, time),
              MathUtils.damp(camera.position.y, targetPos.y, ease, time),
              MathUtils.damp(camera.position.z, targetPos.z, ease, time),
            )
          }
        } else if (app.footerVisible) {
          const time = delta * speed
          const targetPos = new Vector3()
          targetPos.lerpVectors(footerStartPos, footerEndPos, app.percent)
          camera.position.set(
            MathUtils.damp(camera.position.x, targetPos.x, ease, time),
            MathUtils.damp(camera.position.y, targetPos.y, ease, time),
            MathUtils.damp(camera.position.z, targetPos.z, ease, time),
          )
        } else {
          camera.position.copy(headerStartPos)
        }
      }
      //

      // While in header, move towards mouse
      // While in footer, move towards reset point
      if (app.headerVisible && !Settings.mobile) {
        if (position === null) return
        const maxAR = 1.5
        const ar = window.innerWidth / window.innerHeight
        const aspect = normalize(1, maxAR, clamp(1, maxAR, ar))
        const wwdVisible = app.currentPage === 'what-we-do' && app.headerVisible
        const rotationFriction = wwdVisible ? 0.25 : 1
        const maxRotation = mix(20, 7, aspect) * rotationFriction
        const maxX = MathUtils.degToRad(config.rotateX * rotationFriction)
        const maxY = MathUtils.degToRad(maxRotation)
        const dpr = gl.getPixelRatio()
        const mouse = {
          x: clamp(0, 1, (position.x * dpr) / gl.domElement.width),
          y: clamp(0, 1, ((position.y - window.scrollY) * dpr) / gl.domElement.height),
        }
        const x = mix(-maxX, maxX, mouse.y) * config.mouse
        const y = mix(maxY, -maxY, mouse.x) * config.mouse
        currentRotation.x = x
        currentRotation.y = y
      } else {
        currentRotation.x = 0
        currentRotation.y = 0
      }
      targetQuat.setFromEuler(currentRotation)
      cameraContainer.quaternion.slerp(targetQuat, 0.1)

      // Shake
      const yaw = config.maxYaw * yawNoise.noise(state.clock.elapsedTime * config.yawFrequency, 1) * config.shake
      const pitch =
        config.maxPitch * pitchNoise.noise(state.clock.elapsedTime * config.pitchFrequency, 1) * config.shake
      const roll = config.maxRoll * rollNoise.noise(state.clock.elapsedTime * config.rollFrequency, 1) * config.shake
      camera.rotation.set(pitch, yaw, roll)

      if (config.orbit) controls.update()
    })
  }

  return null
}
